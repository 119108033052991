<template>
  <b-container id="controlling-tab-content-container" fluid class="d-flex flex-column align-items-sm-start mt-3 p-1">
    <b-container id="headContainer" fluid class="mb-3 ml-1">
      <b-row align-h="start" align-v="end">
        <b-col lg="1" class="px-0">
          <b-form-select v-model="year" :options="years" @change="setYear" :disabled="dataTableControls.busy">
            <template #first>
              <b-form-select-option :value="null">{{ $t('datetime.select-year') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <b-form-select v-model="month" :options="months" @change="setMonth" :disabled="dataTableControls.busy">
            <template #first>
              <b-form-select-option :value="null">{{ $t('datetime.select-month') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <Datepicker
            id="date-pick-start"
            v-model="datePickStart"
            :locale="this.$store.getters.getLocale"
            :placeholder="this.$t('datetime.start-date')"
            :disabled="dataTableControls.busy"
          />
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <Datepicker
            id="date-pick-end"
            v-model="datePickEnd"
            :locale="this.$store.getters.getLocale"
            :placeholder="this.$t('datetime.end-date')"
            :min="datePickStart"
            :disabled="dataTableControls.busy"
          />
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <vue-autosuggest
            v-model="customerQuery"
            :section-configs="{ default: { limit: 6 } }"
            :suggestions="customerSuggestions"
            :input-props="{ id: 'customerSearch', placeholder: this.$t('validation.search-customer') }"
            @selected="selectCustomerHandler"
            @input="clearSelectedCustomer"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item }}</span>
            </template>
          </vue-autosuggest>
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <vue-autosuggest
            v-model="projectQuery"
            :section-configs="{ default: { limit: 6 } }"
            :suggestions="projectSuggestions"
            :input-props="{ id: 'projectSearch', placeholder: this.$t('validation.search-project') }"
            @selected="selectProjectHandler"
            @input="clearSelectedProject"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item }}</span>
            </template>
          </vue-autosuggest>
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <vue-autosuggest
            v-model="ticketQuery"
            :section-configs="{ default: { limit: 6 } }"
            :suggestions="ticketSuggestions"
            :input-props="{ id: 'ticketSearch', placeholder: this.$t('validation.search-ticket') }"
            @selected="selectTicketHandler"
            @input="clearSelectedTicket"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item }}</span>
            </template>
          </vue-autosuggest>
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <vue-autosuggest
            v-model="userQuery"
            :section-configs="{ default: { limit: 10 } }"
            :suggestions="userSuggestions"
            :input-props="{ id: 'userSearch', placeholder: this.$t('validation.search-user') }"
            @selected="selectUserHandler"
            @input="clearSelectedUser"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item }}</span>
            </template>
          </vue-autosuggest>
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <vue-autosuggest
            v-model="reviewStatusQuery"
            :section-configs="{ default: { limit: 4 } }"
            :suggestions="[{ data: ['Unreviewed', 'Reviewed', 'Highlighted', 'Billed'] }]"
            :input-props="{ id: 'statusSearch', placeholder: this.$t('validation.search-status') }"
            @selected="selectReviewStatusHandler"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item }}</span>
            </template>
          </vue-autosuggest>
        </b-col>
        <b-col lg="1" class="ml-2 px-0">
          <b-button class="top-button" @click="clearFilter">{{ $t('validation.clear-all-filters') }}</b-button>
        </b-col>
      </b-row>

      <b-row align-h="start" class="mt-2">
        <b-col cols="1">
          <b-form-text
            >{{ $t('validation.totalDuration') }}:<br /><strong>{{
              durationInMinutesToTime(totalDuration)
            }}</strong></b-form-text
          >
        </b-col>
        <b-col cols="1" class="ml-2">
          <b-form-text class="text-right"
            >{{ $t('validation.billableDuration') }}:<br /><strong>{{
              durationInMinutesToTime(billableDuration)
            }}</strong></b-form-text
          >
        </b-col>
        <b-col cols="6" class="px-0 ml-2 mr-5">
          <DatepickerQuickfilter
            v-on:datePickStart="datePickStart = $event"
            v-on:datePickEnd="datePickEnd = $event"
            :show-current-month="false"
            :show-last-month="false"
          />
        </b-col>
        <b-col cols="1" class="px-0">
          <b-button class="top-button" variant="info" @click="emitReviewInformation">{{
            $t('validation.review-filtered')
          }}</b-button>
        </b-col>
        <b-col cols="1" class="ml-2 px-0" v-if="isInvoicingEnabled">
          <b-button
            class="top-button"
            variant="info"
            :disabled="!enableInvoiceGeneration"
            :title="invoiceGenerationDisabledTitle"
            @click="emitSubtractInformation"
            >{{ $t('validation.generate-invoice') }}</b-button
          >
        </b-col>
      </b-row>
    </b-container>

    <b-table
      id="validation-datatable"
      show-empty
      :empty-text="$t('general.table.empty-text')"
      :empty-filtered-text="$t('general.table.empty-text')"
      small
      stacked="md"
      hover="hover"
      :tbody-tr-class="rowClass"
      :items="filteredOptions"
      :fields="fields"
      :current-page="dataTableControls.currentPage"
      :per-page="dataTableControls.perPage"
      :sort-by="dataTableControls.sortBy"
      :busy="dataTableControls.busy"
      :sort-desc="true"
      fixed
      responsive
      striped
      @filtered="onFiltered"
      @row-selected="onRowSelect"
      ref="validationTable"
      head-variant="light"
    >
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" />
          <strong>{{ $t('general.table.loading') }}</strong>
        </div>
      </template>
      <!-- generic cell slot template to add tooltips to truncated text cells in datatables -->
      <template v-slot:cell()="row">
        <span v-ellipsis:bottom="row.value">{{ row.value }}</span>
      </template>
      <template v-slot:cell(startDate)="row">
        {{ $date(row.item.startDate).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:cell(durationInMinutes)="row">
        {{ getDurationInTimeFormat(row.item.durationInMinutes) }}
      </template>
      <template v-slot:cell(reviewTimeTrackingUnit.durationInMinutes)="row">
        {{
          getBillableOrNormalDurationInTimeFormat(
            row.item.durationInMinutes,
            row.item.reviewTimeTrackingUnit.durationInMinutes
          )
        }}
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="xs"
          class="mr-1"
          variant="outline-secondary"
          @click="emitEditInformation(row)"
          :disabled="disableEditButtons(row.item)"
          :title="editButtonTitle(row.item)"
        >
          <b-icon icon="pencil" aria-hidden="true" />
        </b-button>
        <b-button
          @click="emitDeleteInformation(row)"
          size="xs"
          variant="outline-danger"
          :disabled="disableEditButtons(row.item)"
          :title="editButtonTitle(row.item)"
        >
          <b-icon icon="trash" aria-hidden="true" />
        </b-button>
      </template>
    </b-table>
    <div class="overflow-auto mb-2">
      <b-pagination
        v-model="dataTableControls.currentPage"
        :total-rows="filteredOptions.length"
        :per-page="dataTableControls.perPage"
        class="mt-2"
      />
    </div>
    <ControllingColorKey />
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import Datepicker from '@/components/GoldflamDatepicker'
import DatepickerQuickfilter from '@/components/DatepickerQuickfilter'
import { LocalDateTimeFormatter, LocalDate } from '@/util/LocalDateTimeFormatter'
import { VueAutosuggest } from 'vue-autosuggest'
import ControllingColorKey from '@/components/controlling/ControllingColorKey'

export default {
  name: 'ControllingValidationTab',
  mixins: [ApiMixin, NotificationMixin],
  components: { Datepicker, DatepickerQuickfilter, VueAutosuggest, ControllingColorKey },
  props: {
    years: {
      required: true,
      type: Array
    },
    months: {
      required: true,
      type: Array
    },
    reloadDataProp: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      items: [],
      month: null,
      year: null,
      defaultYear: LocalDate.currentYear(),
      customerQuery: '',
      selectedCustomer: '',
      projectQuery: '',
      selectedProject: '',
      ticketQuery: '',
      selectedTicket: '',
      userQuery: '',
      selectedUser: '',
      reviewStatusQuery: '',
      selectedReviewStatus: '',
      datePickStart: null,
      datePickEnd: null,
      customerProjectTicketMapping: null,
      dataTableControls: {
        selected: '',
        totalRows: 1,
        currentPage: this.$route.params.pageNumber ? this.$route.params.pageNumber : 1,
        perPage: 50,
        sortBy: 'startDate',
        busy: false
      },
      totalNetPriceSubtraction: 0,
      priceAccumulations: [],
      reviewStatus: 1,
      customerSet: false,
      projectSet: false,
      selectedRows: [],
      reviewTTUs: []
    }
  },
  mounted() {
    this.dataTableControls.busy = true
    this.loadCustomerProjectTicketMapping()
      .then(this.getValidationData)
      .then(() => {
        this.dataTableControls.totalRows = this.items.length
        this.dataTableControls.busy = false
      })
  },
  created() {
    this.setDatepickerFiltersToCurrentWeek()
  },
  watch: {
    datePickStart: function (newDatePickStart) {
      if (this.$dayjs(newDatePickStart).isAfter(this.datePickEnd, 'day')) {
        this.datePickEnd = newDatePickStart
      }
    },
    reloadDataProp: function () {
      this.reloadTable()
    }
  },
  computed: {
    fields() {
      return [
        { key: 'startDate', label: this.$t('timetracking.date'), sortable: true, width: '100px' },
        {
          key: 'durationInMinutes',
          label: this.$t('validation.duration'),
          sortable: false,
          width: '70px',
          formatter: 'durationInMinutesToTime'
        },
        {
          key: 'reviewTimeTrackingUnit.durationInMinutes',
          label: this.$t('validation.billable'),
          sortable: false,
          width: '120px',
          formatter: 'durationInMinutesToTime'
        },
        { key: 'user.fullName', label: this.$t('validation.user'), sortable: true, width: '8%' },
        {
          key: 'ticket.project.customer.fullName',
          label: this.$t('timetracking.customer'),
          sortable: true,
          width: '10%'
        },
        { key: 'ticket.project.fullName', label: this.$t('timetracking.project'), sortable: true, width: '15%' },
        { key: 'ticket.ticketId', label: 'Ticket-ID', sortable: true, width: '90px' },
        { key: 'ticket.name', label: 'Ticket-Name', sortable: true, width: 'auto' },
        { key: 'comment', label: this.$t('timetracking.comment'), sortable: false, width: 'auto' },
        { key: 'actions', label: 'TTU', sortable: false, width: '100px' }
      ]
    },
    filteredOptions() {
      if (!this.items) {
        return []
      }
      return this.items.filter(option => {
        if (!option.ticket.project.customer.fullName.toLowerCase().startsWith(this.selectedCustomer.toLowerCase())) {
          return false
        }
        if (!option.ticket.project.fullName.toLowerCase().startsWith(this.selectedProject.toLowerCase())) {
          return false
        }
        if (!option.ticket.name.toLowerCase().startsWith(this.selectedTicket.toLowerCase())) {
          return false
        }
        if (!option.user.fullName.toLowerCase().startsWith(this.selectedUser.toLowerCase())) {
          return false
        }
        if (this.datePickStart !== '' && this.getDateView(option.startDate) < this.datePickStart) {
          return false
        }
        if (this.datePickEnd !== '' && this.getDateView(option.startDate) > this.datePickEnd) {
          return false
        }
        if (this.selectedReviewStatus !== '') {
          if (
            (this.selectedReviewStatus === 'Unreviewed' && option.reviewTimeTrackingUnit.reviewStatus !== null) ||
            (this.selectedReviewStatus !== 'Unreviewed' &&
              option.reviewTimeTrackingUnit.reviewStatus !== this.selectedReviewStatus.toUpperCase())
          ) {
            return false
          }
        }
        return true
      })
    },
    billableDuration() {
      let billableDuration = 0
      this.filteredOptions.forEach(option => {
        if (option.reviewTimeTrackingUnit.durationInMinutes != null) {
          billableDuration += option.reviewTimeTrackingUnit.durationInMinutes
        } else {
          // durationInMinutes already contains the net duration excluding the break duration
          billableDuration += option.durationInMinutes
        }
      })
      return billableDuration
    },
    totalDuration() {
      let totalDuration = 0
      this.filteredOptions.forEach(option => {
        // durationInMinutes already contains the net duration excluding the break duration
        totalDuration += option.durationInMinutes
      })
      return totalDuration
    },
    customerSuggestions() {
      let customerSet = new Set()
      this.filteredOptions.forEach(option => {
        customerSet.add(option.ticket.project.customer)
      })
      const suggestions = []
      customerSet.forEach(customer => {
        suggestions.push(customer.fullName)
      })
      const filteredSuggestions = suggestions.filter(item => {
        return item.toLowerCase().startsWith(this.customerQuery.toLowerCase())
      })
      return [{ data: [...new Set(filteredSuggestions)] }]
    },
    projectSuggestions() {
      let projectSet = new Set()
      this.filteredOptions.forEach(option => {
        projectSet.add(option.ticket.project)
      })
      const suggestions = []
      projectSet.forEach(project => {
        suggestions.push(project.fullName)
      })
      const filteredSuggestions = suggestions.filter(item => {
        return item.toLowerCase().startsWith(this.projectQuery.toLowerCase())
      })
      return [{ data: [...new Set(filteredSuggestions)] }]
    },
    userSuggestions() {
      let userSet = new Set()
      this.filteredOptions.forEach(option => {
        userSet.add(option.user)
      })
      const suggestions = []
      userSet.forEach(user => {
        suggestions.push(user.fullName)
      })
      const filteredSuggestions = suggestions.filter(item => {
        return item.toLowerCase().startsWith(this.userQuery.toLowerCase())
      })
      return [{ data: [...new Set(filteredSuggestions)] }]
    },
    ticketSuggestions() {
      let ticketSet = new Set()
      this.filteredOptions.forEach(option => {
        ticketSet.add(option.ticket)
      })
      const suggestions = []
      ticketSet.forEach(ticket => {
        suggestions.push(ticket.name)
      })
      const filteredSuggestions = suggestions.filter(item => {
        return item.toLowerCase().startsWith(this.ticketQuery.toLowerCase())
      })
      return [{ data: [...new Set(filteredSuggestions)] }]
    },
    enableInvoiceGeneration() {
      return (
        this.reviewStatusQuery === 'Reviewed' &&
        this.datePickStart !== '' &&
        this.datePickEnd !== '' &&
        (this.projectQuery !== '' || this.customerQuery !== '') &&
        this.filteredOptions.length > 0
      )
    },
    invoiceGenerationDisabledTitle() {
      if (this.enableInvoiceGeneration) {
        return ''
      } else {
        return this.$t('validation.invoice-conditions')
      }
    }
  },
  methods: {
    loadCustomerProjectTicketMapping() {
      let self = this
      return this.getRequest(
        '/table/customers_projects_tickets',
        new RequestConfig()
          .onSuccess(res => {
            self.customerProjectTicketMapping = res.data
          })
          .onError(() => {
            self.customerProjectTicketMapping = {}
          })
      )
    },
    getValidationData() {
      let self = this
      return this.getRequest(
        '/table/finished_user_ttus',
        new RequestConfig()
          .onSuccess(res => {
            self.items = res.data
          })
          .onError(() => {
            self.items = []
          })
      )
    },
    reloadTable() {
      this.dataTableControls.busy = true
      this.getValidationData()
        .then(() => {
          this.$refs.validationTable.refresh()
          this.dataTableControls.busy = false
        })
        .catch(err => {
          this.dataTableControls.busy = false
          this.displayError('Error fetching ReviewTTUs: ' + err)
        })
    },
    setDatepickerFiltersToCurrentWeek() {
      this.datePickStart = LocalDate.mondayInCurrentWeek()
      this.datePickEnd = LocalDate.fridayInCurrentWeek()
    },
    setYear() {
      if (this.year && this.month) {
        this.setMonth()
      } else if (this.year) {
        this.datePickStart = this.year + '-01-01'
        this.datePickEnd = this.year + '-12-31'
      } else {
        this.year = null
        this.month = null
        this.datePickStart = null
        this.datePickEnd = null
      }
    },
    setMonth() {
      let year = this.pickYear()
      if (this.month && this.year) {
        this.datePickStart = LocalDateTimeFormatter.toDate(year + '-' + this.month + '-01')
        this.datePickEnd = LocalDate.lastDayOfMonth(year, this.month)
      } else {
        this.month = null
        this.datePickStart = null
        this.datePickEnd = null
      }
    },
    pickYear() {
      if (!this.year) {
        this.year = this.defaultYear
      }
      return this.year
    },
    selectCustomerHandler(item) {
      if (!item) {
        this.customerQuery = ''
      } else {
        this.customerQuery = item.item
      }
      this.selectedCustomer = this.customerQuery
    },
    clearSelectedCustomer() {
      this.selectedCustomer = ''
    },
    selectProjectHandler(item) {
      if (!item) {
        this.projectQuery = ''
      } else {
        this.projectQuery = item.item
      }
      this.selectedProject = this.projectQuery
    },
    clearSelectedProject() {
      this.selectedProject = ''
    },
    selectTicketHandler(item) {
      if (!item) {
        this.ticketQuery = ''
      } else {
        this.ticketQuery = item.item
      }
      this.selectedTicket = this.ticketQuery
    },
    clearSelectedTicket() {
      this.selectedTicket = ''
    },
    selectUserHandler(item) {
      if (!item) {
        this.userQuery = ''
      } else {
        this.userQuery = item.item
      }
      this.selectedUser = this.userQuery
    },
    clearSelectedUser() {
      this.selectedUser = ''
    },
    selectReviewStatusHandler(item) {
      if (!item) {
        this.reviewStatusQuery = ''
      } else {
        this.reviewStatusQuery = item.item
      }
      this.selectedReviewStatus = this.reviewStatusQuery
    },
    clearFilter() {
      this.customerQuery = ''
      this.selectedCustomer = ''
      this.projectQuery = ''
      this.selectedProject = ''
      this.ticketQuery = ''
      this.selectedTicket = ''
      this.userQuery = ''
      this.selectedUser = ''
      this.reviewStatusQuery = ''
      this.selectedReviewStatus = ''
      this.datePickStart = null
      this.datePickEnd = null
      this.month = null
      this.year = null
    },
    durationInMinutesToTime(durationInMinutes) {
      return LocalDateTimeFormatter.durationInMinutesToTime(durationInMinutes)
    },
    emitReviewInformation() {
      this.reviewTTUs.length = 0
      this.filteredOptions.forEach(ttu => {
        let reviewTimeTrackingUnit
        if (!ttu.reviewTimeTrackingUnit.reviewStatus) {
          reviewTimeTrackingUnit = {
            startDate: ttu.startDate,
            projectName: ttu.ticket.project.fullName,
            ticket: ttu.ticket,
            userName: ttu.user.fullName,
            comment: ttu.comment,
            matchingTimeTrackingUnitId: ttu.matchingTimeTrackingUnitId,
            timeFactor: 1,
            originalDurationInMinutes: ttu.durationInMinutes,
            durationInMinutes: ttu.durationInMinutes,
            reviewStatus: null,
            reviewComment: '',
            timeTrackingUnit: ttu
          }
        } else {
          reviewTimeTrackingUnit = {
            startDate: ttu.startDate,
            projectName: ttu.ticket.project.fullName,
            ticket: ttu.ticket,
            comment: ttu.comment,
            matchingTimeTrackingUnitId: ttu.matchingTimeTrackingUnitId,
            timeFactor: ttu.reviewTimeTrackingUnit.timeFactor / 100,
            originalDurationInMinutes: ttu.durationInMinutes,
            durationInMinutes: ttu.durationInMinutes * (ttu.reviewTimeTrackingUnit.timeFactor / 100),
            reviewStatus: ttu.reviewTimeTrackingUnit.reviewStatus,
            reviewComment: '',
            timeTrackingUnit: ttu
          }
        }
        reviewTimeTrackingUnit.ticket.timeTrackingUnitId = ttu.matchingTimeTrackingUnitId
        this.reviewTTUs.push(reviewTimeTrackingUnit)
      })
      this.$emit('open-review-modal', this.reviewTTUs)
    },
    getPriceAccumulations() {
      let projectCollection = {}
      for (let ttu of this.filteredOptions) {
        if (ttu.ticket.project.id in projectCollection) {
          let project = projectCollection[ttu.ticket.project.id]
          project.price += this.calculateTtuPrice(ttu)
        } else {
          let project = {}
          project.name = ttu.ticket.project.fullName
          project.price = this.calculateTtuPrice(ttu)
          project.subtraction = 0
          project.id = ttu.ticket.project.id
          projectCollection[project.id] = project
        }
      }
      this.priceAccumulations = Object.keys(projectCollection).map(function (key) {
        return projectCollection[key]
      })
    },
    calculateTtuPrice(ttu) {
      // try to calculate with projectHourlyRates per user ...
      if (ttu.ticket.project.projectHourlyRates !== null && ttu.ticket.project.projectHourlyRates.length > 0) {
        let rateForTtuUser = ttu.ticket.project.projectHourlyRates.find(rate => {
          let currentTtuUserRate = rate.users.find(user => user.id === ttu.user.id)
          return currentTtuUserRate !== undefined
        })
        return (rateForTtuUser.hourlyRateInCents * ttu.reviewTimeTrackingUnit.durationInMinutes) / 6000
      }
      // ... otherwise use the defaultHourlyRate of the project or customer
      if (ttu.ticket.project.defaultHourlyRateInCents !== null) {
        return (ttu.ticket.project.defaultHourlyRateInCents * ttu.reviewTimeTrackingUnit.durationInMinutes) / 6000
      } else if (ttu.ticket.project.customer.hourlyRateInCents !== null) {
        return (ttu.ticket.project.customer.hourlyRateInCents * ttu.reviewTimeTrackingUnit.durationInMinutes) / 6000
      } else {
        // hourly rate missing, should be handled at backend
        return 0
      }
    },
    emitSubtractInformation() {
      this.getPriceAccumulations()
      this.$emit(
        'load-subtraction-modal',
        this.priceAccumulations,
        this.filteredOptions,
        this.datePickStart,
        this.datePickEnd
      )
    },
    emitEditInformation(row) {
      this.$emit('open-edit-modal', row.item)
    },
    emitDeleteInformation(row) {
      this.$emit('open-delete-modal', row.item)
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.reviewTimeTrackingUnit.reviewStatus === 'HIGHLIGHTED') return 'table-danger'
      if (item.reviewTimeTrackingUnit.reviewStatus === 'REVIEWED') return 'table-info'
      if (item.reviewTimeTrackingUnit.reviewStatus === 'BILLED') return 'table-success'
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.dataTableControls.totalRows = filteredItems.length
    },
    onRowSelect(items) {
      this.selectedRows = items
    },
    disableEditButtons(ttu) {
      let disabled_statuses = ['REVIEWED', 'BILLED']
      return ttu.reviewTimeTrackingUnit !== null && disabled_statuses.includes(ttu.reviewTimeTrackingUnit.reviewStatus)
    },
    editButtonTitle(ttu) {
      return this.disableEditButtons(ttu) ? this.$t('timetracking.edit-button-disabled') : ''
    },
    getDateView(date) {
      return LocalDateTimeFormatter.toDate(date)
    },
    getDurationInTimeFormat(durationInMinutes) {
      // durationInMinutes already contains the net duration excluding the break duration
      let duration = durationInMinutes
      if (duration < 0) {
        duration = 0
      }
      return LocalDateTimeFormatter.durationInMinutesToTime(duration)
    },
    getBillableOrNormalDurationInTimeFormat(originalDurationInMinutes, reviewDurationInMinutes) {
      let duration = reviewDurationInMinutes !== null ? reviewDurationInMinutes : originalDurationInMinutes
      if (duration < 0) {
        duration = 0
      }
      return LocalDateTimeFormatter.durationInMinutesToTime(duration)
    }
  }
}
</script>

<style scoped></style>
