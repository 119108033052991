<template>
  <div class="d-flex flex-column align-items-sm-start">
    <ControllingEditModal
      ref="editModal"
      :visible.sync="modalControl.showEditModal"
      :time-tracking-unit-to-edit="timeTrackingUnit"
      :update-ttu-prop="updateTtuProp"
      @reload-table="reloadValidationTable"
    >
    </ControllingEditModal>
    <ControllingReviewModal
      ref="reviewModal"
      :visible.sync="modalControl.showReviewModal"
      :reviewTTUs="reviewTTUs"
      @reload-table="reloadValidationTable"
    >
    </ControllingReviewModal>
    <ControllingSubtractionModal
      ref="subtractionModal"
      :visible.sync="modalControl.showSubtractionModal"
      :ttus="invoiceTTUs"
      :price-accumulations="priceAccumulations"
      :start-date="invoiceStartDate"
      :end-date="invoiceEndDate"
    >
    </ControllingSubtractionModal>
    <ControllingModal
      ref="controllingModal"
      :modal-title="controllingModalTitle"
      :visible.sync="modalControl.showControllingModal"
      :customer-name="controllingModalCustomer"
      :project-name="controllingModalProject"
      :ttus="ttusForTicket"
      @reload-table="reloadControllingData"
    >
    </ControllingModal>
    <b-modal
      v-model="modalControl.showDeleteModal"
      :title="$t('timetracking.modals.deleteModal.deleteTimeTrackingUnitTitle')"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :ok-title="$t('general.delete')"
      ok-variant="danger"
      @ok="deleteTimeTackingUnit"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      >{{ $t('timetracking.delete-text-ttu') }}
    </b-modal>

    <b-card no-body class="w-100">
      <b-card-header class="pb-0 tabs">
        <!--
          use b-nav instead of b-tabs because b-tabs doesn't support URL navigation as described here:
          - https://bootstrap-vue.org/docs/components/tabs
            "For navigation based tabs (i.e. tabs that would change the URL), use the <b-nav> component instead."
          - https://stackoverflow.com/a/56915690/1128689
            "b-tabs is supposed to be used for local (non-url) based content (the href prop on b-tab is deprecated)"
       -->
        <b-nav tabs justified>
          <b-nav-item to="#month" @click="setActiveTab('month')" :active="isActiveTab('month') || isDefaultTab">
            {{ $t('controlling.month-tab') }}
          </b-nav-item>
          <b-nav-item to="#week" @click="setActiveTab('week')" :active="isActiveTab('week')">
            {{ $t('controlling.week-tab') }}
          </b-nav-item>
          <b-nav-item to="#employee" @click="setActiveTab('employee')" :active="isActiveTab('employee')">
            {{ $t('controlling.employee-tab') }}
          </b-nav-item>
          <b-nav-item to="#customer" @click="setActiveTab('customer')" :active="isActiveTab('customer')">
            {{ $t('controlling.customer-tab') }}
          </b-nav-item>
          <b-nav-item to="#table" @click="setActiveTab('table')" :active="isActiveTab('table')">
            {{ $t('controlling.table-tab') }}
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body class="p-2 pt-4">
        <div v-if="isActiveTab('month') || isDefaultTab" class="tab-pane">
          <ControllingMonthTab
            ref="monthTab"
            :years="years"
            :months="months"
            :reloadDataProp="reloadControl.reloadMonthData"
            @row-click="setCustomerAndProjectAndLoadTTusForTicket"
            @project-header-row-click="getTTUsForTopLevelReview"
            @customer-header-row-click="getTTUsForTopLevelReview"
            @time-header-row-click="getTTUsForTimespan"
          />
        </div>
        <div v-if="isActiveTab('week')" class="tab-pane">
          <ControllingWeekTab
            ref="weekTab"
            :years="years"
            :reloadDataProp="reloadControl.reloadWeekData"
            @row-click="setCustomerAndProjectAndLoadTTusForTicket"
            @project-header-row-click="getTTUsForTopLevelReview"
            @customer-header-row-click="getTTUsForTopLevelReview"
            @time-header-row-click="getTTUsForTimespan"
            @project-cost-group-header-row-click="getTTUsForTopLevelReview"
          />
        </div>
        <div v-if="isActiveTab('employee')" class="tab-pane">
          <ControllingEmployeeTab
            ref="employeeTab"
            :years="years"
            :months="months"
            :reloadDataProp="reloadControl.reloadUserData"
            @row-click="setCustomerAndProjectAndLoadTTusForTicket"
            @user-header-row-click="getTTUsForTimespan"
            @project-header-row-click="getTTUsForTopLevelReview"
            @customer-header-row-click="getTTUsForTopLevelReview"
          />
        </div>
        <div v-if="isActiveTab('customer')" class="tab-pane">
          <ControllingCustomerTab
            ref="customerTab"
            :years="years"
            :customers="allCustomer"
            :projects="allProjects"
            :reloadDataProp="reloadControl.reloadCustomerData"
            @row-click="getTTUsForTicket"
            @row-click-project="getTTUsForUserAndProject"
          />
        </div>
        <div v-if="isActiveTab('table')" class="tab-pane">
          <ControllingValidationTab
            ref="validationTab"
            :years="years"
            :months="months"
            :reloadDataProp="reloadControl.reloadValidationData"
            @open-review-modal="openReviewModal"
            @load-subtraction-modal="loadSubtractionModal"
            @open-edit-modal="openEditModal"
            @open-delete-modal="openDeleteModal"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { LocalDateTimeFormatter, LocalDate, StaticDates } from '@/util/LocalDateTimeFormatter'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import ControllingMonthTab from '@/components/controlling/ControllingMonthTab'
import ControllingWeekTab from '@/components/controlling/ControllingWeekTab'
import ControllingEmployeeTab from '@/components/controlling/ControllingEmployeeTab'
import ControllingValidationTab from '@/components/controlling/ControllingValidationTab'
import ControllingCustomerTab from '@/components/controlling/ControllingCustomerTab'
import ControllingModal from '@/components/controlling/modals/ControllingModal'
import ControllingEditModal from '@/components/controlling/modals/ControllingEditModal'
import ControllingReviewModal from '@/components/controlling/modals/ControllingReviewModal'
import ControllingSubtractionModal from '@/components/controlling/modals/ControllingSubtractionModal'

export default {
  name: 'MainValidation',
  mixins: [ApiMixin, NotificationMixin],
  components: {
    ControllingCustomerTab,
    ControllingMonthTab,
    ControllingWeekTab,
    ControllingEmployeeTab,
    ControllingValidationTab,
    ControllingModal,
    ControllingEditModal,
    ControllingReviewModal,
    ControllingSubtractionModal
  },
  data() {
    return {
      month: null,
      year: null,
      defaultYear: LocalDate.currentYear(),
      months: StaticDates.monthListAsSelectArray(),
      years: [],
      activeTab: 'month',
      allProjects: [],
      allCustomer: [],
      allUsers: [],
      reviewStatus: 1,
      customerSet: false,
      projectSet: false,
      timeTrackingUnit: {
        date: '',
        startDate: null,
        startTime: '',
        endDate: null,
        endTime: '',
        breakDurationInMinutes: null,
        comment: '',
        status: null,
        matchingTimeTrackingUnitId: '',
        ticket: {
          id: '',
          name: '',
          ticketId: '',
          project: {
            id: '',
            fullName: '',
            customer: {
              fullName: ''
            }
          }
        }
      },
      reviewTimeTrackingUnit: {
        startDate: '',
        projectName: '',
        ticket: {
          timeTrackingUnitId: '',
          id: '',
          name: '',
          ticketId: '',
          project: {
            id: '',
            fullName: '',
            customer: {
              fullName: ''
            }
          }
        },
        userName: '',
        comment: '',
        matchingTimeTrackingUnitId: '',
        timeFactor: null,
        durationInMinutes: null,
        reviewComment: '',
        reviewStatus: null
      },
      reviewTTUs: [],
      priceAccumulations: [],
      modalControl: {
        showEditModal: false,
        showReviewModal: false,
        showSubtractionModal: false,
        showDeleteModal: false,
        showControllingModal: false,
        showTTUsForTickets: false
      },
      reloadControl: {
        reloadMonthData: false,
        reloadWeekData: false,
        reloadUserData: false,
        reloadValidationData: false,
        reloadCustomerData: false
      },
      updateTtuProp: false,
      controllingModalTitle: '',
      controllingModalCustomer: '',
      controllingModalProject: '',
      selectedRows: [],
      ttusForTicket: [],
      ticketsForProject: [],
      invoiceStartDate: '',
      invoiceEndDate: '',
      invoiceTTUs: [],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark'
    }
  },
  created() {
    this.years = StaticDates.yearListSinceAsSelectArray(this.$store.getters.getConfig.APP_FIRST_YEAR)
    // TIM-356 set the default datatable filters as early as possible to avoid
    // multiple re-computations of the computed prop "filteredOptions"
  },
  mounted() {
    this.getAllCustomer()
    this.getAllProjects()
    this.getAllUsers()
  },
  computed: {
    isDefaultTab() {
      return this.$route.hash === '#' || this.$route.hash === ''
    }
  },
  methods: {
    isActiveTab(tabName) {
      return this.$route.hash === '#' + tabName
    },
    reloadValidationTable() {
      this.reloadControl.reloadValidationData = !this.reloadControl.reloadValidationData
    },
    setActiveTab(tabName) {
      this.activeTab = tabName
    },
    reloadControllingData() {
      let type = this.activeTab
      switch (type) {
        case 'month':
          this.reloadControl.reloadMonthData = !this.reloadControl.reloadMonthData
          break
        case 'week':
          this.reloadControl.reloadWeekData = !this.reloadControl.reloadWeekData
          break
        case 'employee':
          this.reloadControl.reloadUserData = !this.reloadControl.reloadUserData
          break
        case 'customer':
          this.reloadControl.reloadCustomerData = !this.reloadControl.reloadCustomerData
      }
    },
    getAllProjects() {
      let self = this
      this.getRequest(
        '/projects',
        new RequestConfig().onSuccess(res => {
          self.allProjects = res.data
        })
      )
    },
    getAllCustomer() {
      let self = this
      this.getRequest(
        '/customers/simple',
        new RequestConfig().onSuccess(res => {
          self.allCustomer = res.data
        })
      )
    },
    getAllUsers() {
      let self = this
      this.getRequest(
        '/users',
        new RequestConfig().onSuccess(res => {
          self.allUsers = res.data
        })
      )
    },
    getTTUsForTopLevelReview(object) {
      let customerNameForTitle = object.customer ? object.customer : ''
      this.controllingModalTitle = object.project ? object.project : customerNameForTitle
      let month = object.month !== undefined ? object.month : ''
      let year = object.year !== undefined ? object.year : ''
      let week = object.week !== undefined ? object.week : ''
      let project_name = object.project !== undefined ? encodeURIComponent(object.project) : ''
      let user_name = object.userName !== undefined ? encodeURIComponent(object.userName) : ''
      let project_cost_group = object.projectCostGroup !== undefined ? object.projectCostGroup : ''
      let customer_name = encodeURIComponent(object.customer)
      let self = this
      return this.getRequest(
        `/timetracking_units/customer/${customer_name}?project_name=${project_name}&user_name=${user_name}&year=${year}&month=${month}&week=${week}`,
        new RequestConfig()
          .onSuccess(res => {
            self.ttusForTicket = res.data
            self.ttusForTicket.forEach(ttu => {
              if (!ttu.reviewTimeTrackingUnit) {
                ttu.reviewTimeTrackingUnit = { reviewStatus: null, timeFactor: 1 }
              } else {
                ttu.reviewTimeTrackingUnit.timeFactor = ttu.reviewTimeTrackingUnit.timeFactor / 100
              }
            })
            this.modalControl.showControllingModal = true
          })
          .onError(() => {
            self.ttusForTicket = []
          })
      )
    },
    getTTUsForTimespan(object) {
      this.controllingModalTitle = object.title
      let year = object.year
      let month = object.month ? object.month : ''
      let week = object.week ? object.week : ''
      let user_name = object.user ? encodeURIComponent(object.user) : ''
      let self = this
      return this.getRequest(
        `/timetracking_units/timespan?year=${year}&month=${month}&week=${week}&user_name=${user_name}`,
        new RequestConfig()
          .onSuccess(res => {
            self.ttusForTicket = res.data
            self.ttusForTicket.forEach(ttu => {
              if (!ttu.reviewTimeTrackingUnit) {
                ttu.reviewTimeTrackingUnit = { reviewStatus: null, timeFactor: 1 }
              } else {
                ttu.reviewTimeTrackingUnit.timeFactor = ttu.reviewTimeTrackingUnit.timeFactor / 100
              }
            })
            this.modalControl.showControllingModal = true
          })
          .onError(() => {
            self.ttusForTicket = []
          })
      )
    },
    setCustomerAndProjectAndLoadTTusForTicket(object) {
      if (object.ticket.customerName) {
        this.controllingModalCustomer = object.ticket.customerName
      } else {
        this.controllingModalCustomer = ''
      }
      if (object.ticket.projectName) {
        this.controllingModalProject = object.ticket.projectName
      } else {
        this.controllingModalProject = ''
      }
      this.getTTUsForTicket(object)
    },
    getTTUsForTicket(object) {
      let ticketName = object.ticket.name ? object.ticket.name : object.ticket
      this.controllingModalTitle = object.modalTitle ? object.modalTitle : ticketName
      let ticketId = object.ticket.id
      let projectCostGroup = object.ticket.projectCostGroup ? object.ticket.projectCostGroup : ''
      let month = object.month
      let year = object.year
      let week = object.week
      let userName = encodeURIComponent(object.user)
      let wholeTimespan = object.wholeTimespan ? object.wholeTimespan : false
      let self = this
      return this.getRequest(
        `/timetracking_units/ticket/${ticketId}?year=${year}&month=${month}&week=${week}&userName=${userName}&wholeTimespan=${wholeTimespan}&projectCostGroup=${projectCostGroup}`,
        new RequestConfig()
          .onSuccess(res => {
            self.ttusForTicket = res.data
            self.ttusForTicket.forEach(ttu => {
              if (!ttu.reviewTimeTrackingUnit) {
                ttu.reviewTimeTrackingUnit = { reviewStatus: null, timeFactor: 1 }
              } else {
                ttu.reviewTimeTrackingUnit.timeFactor = ttu.reviewTimeTrackingUnit.timeFactor / 100
              }
            })
            this.modalControl.showControllingModal = true
          })
          .onError(() => {
            self.ttusForTicket = []
          })
      )
    },
    getTTUsForUserAndProject(object) {
      this.controllingModalTitle = object.modalTitle ? object.modalTitle : object.project.name
      let customerName = encodeURIComponent(object.customer)
      let projectName = encodeURIComponent(object.project)
      let userName = encodeURIComponent(object.user)
      let year = encodeURIComponent(object.year)
      let self = this
      return this.getRequest(
        `/timetracking_units/project/${projectName}?customer_name=${customerName}&user_fullname=${userName}&year=${year}`,
        new RequestConfig()
          .onSuccess(res => {
            self.ttusForTicket = res.data
            self.ttusForTicket.forEach(ttu => {
              if (!ttu.reviewTimeTrackingUnit) {
                ttu.reviewTimeTrackingUnit = { reviewStatus: null, timeFactor: 1 }
              } else {
                ttu.reviewTimeTrackingUnit.timeFactor = ttu.reviewTimeTrackingUnit.timeFactor / 100
              }
            })
            this.modalControl.showControllingModal = true
          })
          .onError(() => {
            self.ttusForTicket = []
          })
      )
    },
    openEditModal(row) {
      const { ticket, startDate, endDate, breakDurationInMinutes, comment, status } = row
      this.timeTrackingUnit = {
        date: LocalDateTimeFormatter.toDate(startDate),
        startDate: startDate,
        startTime: LocalDateTimeFormatter.toTime(startDate),
        endDate: endDate,
        endTime: LocalDateTimeFormatter.toTime(endDate),
        comment: comment,
        status: status,
        breakDurationInMinutes: breakDurationInMinutes
      }
      this.timeTrackingUnit.ticket = {
        name: ticket.name,
        id: ticket.id,
        ticketId: ticket.ticketId
      }
      this.timeTrackingUnit.ticket.project = {
        id: ticket.project.id,
        fullName: ticket.project.fullName
      }
      this.timeTrackingUnit.ticket.project.customer = {
        id: ticket.project.customer.id,
        fullName: ticket.project.customer.fullName
      }
      this.timeTrackingUnit.matchingTimeTrackingUnitId = row.matchingTimeTrackingUnitId
      this.modalControl.showEditModal = true
      this.updateTtuProp = !this.updateTtuProp
    },
    openDeleteModal(row) {
      this.timeTrackingUnit.matchingTimeTrackingUnitId = row.matchingTimeTrackingUnitId
      this.modalControl.showDeleteModal = true
    },
    openReviewModal(passedReviewTTUs) {
      this.reviewTTUs = passedReviewTTUs
      this.modalControl.showReviewModal = true
    },
    setReviewStatus(status, listOfTTUs) {
      if (listOfTTUs) {
        // we are in the new controlling modal
        listOfTTUs.forEach(ttu => {
          ttu.reviewStatus = status
          ttu.reviewTimeTrackingUnit.reviewStatus = status
        })
      } else {
        // we are in the legacy validation modal
        this.reviewTTUs.forEach(ttu => {
          ttu.reviewStatus = status
        })
      }
    },
    deleteTimeTackingUnit() {
      const id = this.timeTrackingUnit.matchingTimeTrackingUnitId
      let self = this
      this.deleteRequest(
        `/timetracking_units/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('timetracking.ttu-deleted-success'))
          self.$refs.validationTab.reloadTable()
        })
      )
      this.modalControl.showDeleteModal = false
    },
    loadSubtractionModal(priceAccumulations, ttus, start, end) {
      this.invoiceTTUs = ttus
      this.invoiceStartDate = start
      this.invoiceEndDate = end
      this.priceAccumulations = priceAccumulations
      this.modalControl.showSubtractionModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

#selectedTicketBreadcrumb {
  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }
}

#editTicketBreadcrumb {
  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }
}

.card-body {
  padding: 0.3rem;
}

#runningTimetrackingUnitBreadcrumb {
  margin: 0;
  padding: 0.5rem;

  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }
}

.top-button {
  width: 100%;
}

.ml-40px {
  margin-left: 40px;
}

#editTimetrackingUnitBreadcrumb {
  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }
}
</style>

<style lang="scss">
.card-header.tabs {
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  & .nav-tabs {
    margin-bottom: -1px;
  }
}

#autosuggest input {
  &,
  &:focus {
    padding: 6px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    text-indent: 7px;
    outline: none;
  }
}

#autosuggest .autosuggest__input-open {
  border: none;
}

.autosuggest__results-container {
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  background: white;
  padding: 0;
  max-height: 200px;
  overflow: auto;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  text-indent: 10px;

  &:active,
  &:hover,
  &:focus,
  &.autosuggest__results-item--highlighted {
    background-color: #ddd;
  }
}

.hidden_header {
  display: none;
}

.b-table-top-row td {
  border-top: none;
  pointer-events: none;
  border-bottom: ridge;
}

.controllingTableRow {
  cursor: pointer;
}

.controllingTableFirstCol {
  width: 70%;
}

.controllingWeekFirstCol {
  width: 15%;
}

.controllingWeekFSecondCol {
  width: 60%;
}
</style>
