<template>
  <b-table
    class="mb-0 controlling-project-table-wrapper"
    small
    fixed
    hover="hover"
    thead-class="hidden_header"
    :fields="fields"
    :items="[]"
  >
    <template #top-row="row">
      <td style="width: 15%" class="align-bottom pl-0 font-weight-bold">
        <div class="align-items-center">
          <span class="pt-1">{{ rootElement.name }}</span>
          <ControllingReviewHeaderButton
            v-if="!hideFirstLevelHeaderButton"
            class="ml-3"
            :element="row"
            @header-row-click="emitCustomerInfo"
          />
        </div>
      </td>
      <td style="width: 85%" class="align-bottom">
        <AggregatedDurationTable :projects="rootElement.childAggregations" />
      </td>
    </template>
    <template #bottom-row>
      <td style="width: 15%" class="px-0" />
      <td class="px-0">
        <div
          v-for="project in rootElement.childAggregations"
          :key="project.name"
          class="controlling-row-single-project"
        >
          <ControllingInnerTable
            :show-review-button="!hideSecondLevelHeaderButton"
            :element="project"
            @row-click="emitTicketInfo"
            @header-row-click="emitElementInfo"
          />
        </div>
      </td>
    </template>
  </b-table>
</template>

<script>
import ControllingInnerTable from './ControllingInnerTable'
import AggregatedDurationTable from './AggregatedDurationTable'
import { LocalDate } from '@/util/LocalDateTimeFormatter'
import ControllingReviewHeaderButton from '@/components/controlling/ControllingReviewHeaderButton'

export default {
  name: 'ControllingThreeLevelInnerTable',
  components: { AggregatedDurationTable, ControllingInnerTable, ControllingReviewHeaderButton },
  props: {
    rootElement: {
      type: Object,
      required: true
    },
    isWeekTable: {
      type: Boolean,
      required: false,
      default: false
    },
    isCustomerTable: {
      type: Boolean,
      required: false,
      default: false
    },
    year: {
      type: Number,
      required: false,
      default: LocalDate.currentYear()
    },
    hideFirstLevelHeaderButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSecondLevelHeaderButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    fields() {
      return [
        { key: 'rootElement', label: '', sortable: false, width: '70%' },
        { key: 'projectTable', label: '', sortable: false }
      ]
    },
    weeksOfTheYear() {
      return LocalDate.weeksInYear(this.year)
    }
  },
  methods: {
    emitTicketInfo(ticket) {
      if (this.rootElement.type === 'customer') {
        ticket.customerName = this.rootElement.name
      }
      this.$emit('row-click', ticket)
    },
    emitElementInfo(element) {
      let emitObject = {}
      if (element.type === 'project') {
        emitObject.project = element.name
        emitObject.customer = this.rootElement.name
        emitObject.customerType = this.rootElement.type
        this.$emit('project-header-row-click', emitObject)
      } else if (element.type === 'year') {
        if (this.rootElement.type === 'ticket') {
          emitObject.type = 'ticketYear'
          emitObject.year = element.name
          emitObject.ticket = this.rootElement.name
        } else {
          emitObject.type = 'projectYear'
          emitObject.year = element.name
          emitObject.project = this.rootElement.name
        }
        this.$emit('year-header-row-click', emitObject)
      } else if (element.type === 'projectCostGroup') {
        emitObject.type = 'projectCostGroup'
        emitObject.projectCostGroup = element.name
        emitObject.project = this.rootElement.name
        this.$emit('cost-group-header-row-click', emitObject)
      }
    },
    emitCustomerInfo() {
      let emitObject = {}
      emitObject.customer = this.rootElement.name
      emitObject.year = this.year
      this.$emit('customer-header-row-click', emitObject)
    }
  }
}
</script>

<style scoped>
.controlling-row-single-project + .controlling-row-single-project {
  margin-top: 1.5rem;
}
</style>
