<template>
  <b-row class="controlling-color-keys" align-h="end">
    <b-col sm="auto" class="pr-sm-1">
      <b-alert class="controlling-color-key mb-0" show variant="success">{{
        $t('controlling.colorKey.green')
      }}</b-alert>
    </b-col>
    <b-col sm="auto" class="pr-sm-1">
      <b-alert class="controlling-color-key mb-0" show variant="info">{{ $t('controlling.colorKey.blue') }}</b-alert>
    </b-col>
    <b-col sm="auto" class="pr-sm-1">
      <b-alert class="controlling-color-key mb-0" show variant="danger">{{ $t('controlling.colorKey.red') }}</b-alert>
    </b-col>
    <b-col sm="auto">
      <b-alert class="controlling-color-key mb-0" show variant="warning">{{
        $t('controlling.colorKey.yellow')
      }}</b-alert>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ControllingColorKey'
}
</script>

<style scoped>
.controlling-color-key {
  line-height: 1rem;
  font-size: 0.8em;
  padding: 5px 15px;
  text-align: center;
}
</style>
