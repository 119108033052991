<template>
  <b-button size="xs" variant="secondary" class="review-button mb-1" @click="emitElementInfo">Review</b-button>
</template>

<script>
export default {
  name: 'ControllingReviewHeaderButton',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitElementInfo(element) {
      this.$emit('header-row-click', element)
    }
  }
}
</script>

<style scoped>
.b-table-top-row td {
  pointer-events: auto;
}

.review-button {
  max-height: 32px;
}
</style>
