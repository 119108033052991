<template>
  <b-modal
    v-model="visibleSync"
    size="xxl"
    :title="modalTitle"
    centered
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-bg-variant="footerBgVariant"
    :footer-text-variant="footerTextVariant"
  >
    <b-container fluid>
      <b-row class="mb-4">
        <b-col cols="6">
          <span>{{ $t('validation.change-review-status-all-items') }}</span>
          <span
            v-if="containsRunningTtu"
            v-b-tooltip.hover
            :title="$t('controlling.warningContainsRunningTtus')"
            class="px-2"
          >
            <b-icon icon="exclamation-triangle-fill" variant="danger" scale="1.5" />
          </span>
          <b-button
            v-for="button in reviewStatusButtons"
            :disabled="changeTicketActive"
            :key="button.text"
            class="ml-2"
            size="sm"
            @click="setReviewStatus(button.value)"
            >{{ button.text }}</b-button
          >
        </b-col>
        <b-col cols="2">
          <b-form-text
            >{{ $t('validation.totalDuration') }}: <strong>{{ totalDuration }}</strong></b-form-text
          >
        </b-col>
        <b-col cols="2">
          <b-form-text
            >{{ $t('controlling.billable') }}:
            <strong>{{ totalBillableDuration }}</strong>
          </b-form-text>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="2">
          <b-button class="w-100" size="sm" @click="toggleChangeTicketMode">
            {{
              !changeTicketActive
                ? $t('controlling.controllingModal.toggle-button-change-ticket')
                : $t('controlling.controllingModal.toggle-button-cancel')
            }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="8">
          <b-collapse id="collapse-4" v-model="changeTicketActive" class="mt-2">
            <b-card>
              <b-row class="mb-4">
                <b-col cols="8">
                  <vue-typeahead-bootstrap
                    :placeholder="'Suche nach neuem Ticket'"
                    ref="searchTicketsTypeahead"
                    v-model="ticketSuggestionQuery"
                    @hit="newTicket = $event"
                    @input="ticketSuggestionDebouncer"
                    :show-all-results="true"
                    :data="ticketSuggestionList"
                    :showOnFocus="true"
                    :serializer="ticket => ticketDropdownSerializer(ticket)"
                    :prepend="'Neues ' + $t('general.ticket')"
                    class="flex-fill"
                    :maxMatches="ticketSuggestionQuery.length > 0 ? ticketSuggestionList.length : 20"
                  />
                </b-col>
                <b-col cols="4">
                  <b-button
                    :disabled="noTTUSelected || noTicketSelected"
                    class="w-100"
                    variant="primary"
                    @click="changeTicketForTTUs"
                    v-b-tooltip.hover
                    :title="changeTicketButtonTooltip"
                  >
                    {{ $t('controlling.controllingModal.save-ticket-change') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-alert
                show
                variant="warning"
                class="p-3 mb-2"
                v-if="newTicket && newTicket.project.fullName !== projectName"
              >
                <span v-if="newTicket.project.customer.fullName !== customerName">
                  {{ $t('controlling.controllingModal.ticket-warning-1') }}
                </span>
                <span v-else>
                  {{ $t('controlling.controllingModal.ticket-warning-2') }}
                </span>
              </b-alert>
              <b-alert show variant="info" class="p-3 mb-0" v-if="changeTicketActive">
                <span>
                  {{ $t('controlling.controllingModal.ticket-info') }}
                </span>
              </b-alert>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>
      <b-table
        id="review-ttus-for-ticket-datatable"
        show-empty
        small
        fixed
        stacked="xxl"
        :hover="!changeTicketActive"
        striped
        :tbody-tr-class="rowClass"
        head-variant="light"
        :items="ttus"
        :fields="fields"
      >
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
        </template>
        <template #head(changeTicketForTTU)>
          <b-icon icon="file-earmark-check" class="text-center" />
        </template>
        <template v-slot:cell(changeTicketForTTU)="row">
          <b-form-checkbox
            :id="'checkbox' + row.item.id"
            name="checkbox-1"
            v-model="row.isChecked"
            :value="true"
            class="text-center ml-2"
            @change="addOrRemoveTtuId($event, row.item.id)"
            :unchecked-value="false"
            :disabled="row.item.status === 'RUNNING'"
          />
        </template>
        <template v-slot:cell(comment)="row">
          <span v-ellipsis:bottom="row.value" size="sm" style="overflow: hidden; text-overflow: ellipsis">{{
            row.item.comment
          }}</span>
        </template>
        <template v-slot:cell(reviewTimeTrackingUnit.reviewComment)="row">
          <span v-ellipsis:bottom="row.value" size="sm" style="overflow: hidden; text-overflow: ellipsis">{{
            row.item.reviewTimeTrackingUnit.reviewComment
          }}</span>
        </template>
        <template v-slot:cell(startTime)="row">
          {{ formatToTime(row.item.startDate) }}
        </template>
        <template v-slot:cell(endTime)="row">
          {{ formatToTime(row.item.endDate) }}
        </template>
        <template v-slot:cell(breakDurationInMinutes)="row">
          <span :class="{ 'text-black-30': row.value === '00:00' }">{{ row.value }}</span>
        </template>
        <template v-slot:cell(reviewTimeTrackingUnit.timeFactor)="row">
          <b-form-input
            v-model="row.item.reviewTimeTrackingUnit.timeFactor"
            :disabled="editingIsDisabled(row)"
            :class="!changeTicketActive ? 'review-input' : ''"
            class="w-75"
            size="sm"
            type="number"
            step="0.01"
          />
        </template>
        <template v-slot:cell(billableDuration)="row">
          {{
            getBillableOrNormalDurationInTimeFormat(
              row.item.durationInMinutes,
              row.item.reviewTimeTrackingUnit.timeFactor
            )
          }}
        </template>
        <template v-slot:cell(reviewTimeTrackingUnit.reviewComment)="row">
          <b-form-input
            v-model="row.item.reviewTimeTrackingUnit.reviewComment"
            :disabled="editingIsDisabled(row)"
            :class="!changeTicketActive ? 'review-input' : ''"
            size="sm"
          />
        </template>
        <template v-slot:cell(reviewTimeTrackingUnit.reviewStatus)="row">
          <div
            v-if="row.item.status === 'RUNNING'"
            v-b-tooltip.hover
            :title="$t('controlling.warningContainsRunningTtus')"
            class="text-center align-middle"
          >
            <b-icon icon="exclamation-triangle-fill" variant="danger" scale="1.5" />
          </div>
          <b-button
            v-else
            v-model="row.item.reviewTimeTrackingUnit.reviewStatus"
            class="w-100"
            size="sm"
            :disabled="editingIsDisabled(row)"
            @click="toggleReviewStatus(row.item.reviewTimeTrackingUnit)"
          >
            {{
              row.item.reviewTimeTrackingUnit.reviewStatus === null
                ? 'UNREVIEWED'
                : row.item.reviewTimeTrackingUnit.reviewStatus
            }}</b-button
          >
        </template>
      </b-table>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          class="float-right"
          variant="primary"
          :disabled="changeTicketActive"
          @click="saveReviewedTimeTrackingUnits"
          v-b-tooltip.hover
          :title="changeTicketActive ? $t('controlling.controllingModal.review-save-button-tooltip') : ''"
        >
          {{ $t('general.save') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { debounce } from 'vue-debounce'

export default {
  name: 'controllingModal',
  mixins: [ApiMixin, NotificationMixin],
  components: { VueTypeaheadBootstrap },
  props: {
    modalTitle: {
      required: true,
      type: String
    },
    projectName: {
      required: false,
      type: String
    },
    customerName: {
      required: false,
      type: String
    },
    ttus: {
      required: true,
      type: Array
    },
    visible: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      reviewStatusButtons: StaticSelectOpts.reviewStatusOptions,
      changeTicketActive: false,
      ttuIdsToChangeTicket: [],
      newTicket: null,
      recentTickets: [],
      ticketSuggestionList: [],
      ticketSuggestionQuery: ''
    }
  },
  mounted() {
    this.getRecentTickets()
  },
  computed: {
    noTTUSelected() {
      return this.ttuIdsToChangeTicket.length === 0
    },
    noTicketSelected() {
      return this.newTicket === null
    },
    changeTicketButtonTooltip() {
      if (this.noTTUSelected && this.noTicketSelected) {
        return this.$t('controlling.controllingModal.ticket-change-save-button-tooltip-1')
      } else if (this.noTTUSelected) {
        return this.$t('controlling.controllingModal.ticket-change-save-button-tooltip-2')
      } else if (this.noTicketSelected) {
        return this.$t('controlling.controllingModal.ticket-change-save-button-tooltip-3')
      } else {
        return ''
      }
    },
    containsRunningTtu() {
      return this.ttus.some(ttu => ttu.status === 'RUNNING')
    },
    fields() {
      var fields = [
        { key: 'user.fullName', label: this.$t('validation.user'), sortable: true },
        {
          key: 'startDate',
          label: this.$t('datetime.start-date'),
          sortable: true,
          formatter: value => {
            return this.formatToLocaleDate(value)
          },
          width: '100px'
        },
        {
          key: 'endDate',
          label: this.$t('datetime.end-date'),
          sortable: true,
          formatter: value => {
            return this.formatToLocaleDate(value)
          },
          width: '100px'
        },
        {
          key: 'startTime',
          label: this.$t('general.start'),
          sortable: true,
          formatter: value => {
            return this.formatToTime(value)
          },
          width: '60px'
        },
        {
          key: 'endTime',
          label: this.$t('general.end'),
          sortable: true,
          formatter: value => {
            return this.formatToTime(value)
          },
          width: '60px'
        },
        {
          key: 'breakDurationInMinutes',
          label: this.$t('controlling.break-duration'),
          sortable: true,
          formatter: value => {
            return this.durationInMinutesToTime(value)
          },
          width: '60px'
        },
        {
          key: 'durationInMinutes',
          label: this.$t('controlling.duration'),
          sortable: true,
          formatter: value => {
            return this.durationInMinutesToTime(value)
          },
          width: '60px'
        },
        {
          key: 'reviewTimeTrackingUnit.timeFactor',
          label: this.$t('controlling.table.time-factor'),
          sortable: true,
          width: '70px'
        },
        {
          key: 'billableDuration',
          label: this.$t('controlling.billable'),
          sortable: true,
          formatter: value => {
            return this.durationInMinutesToTime(value)
          },
          width: '80px'
        },
        { key: 'comment', label: this.$t('general.comment'), sortable: true },
        { key: 'reviewTimeTrackingUnit.reviewComment', label: this.$t('validation.review-comment'), sortable: true },
        {
          key: 'reviewTimeTrackingUnit.reviewStatus',
          label: this.$t('validation.review-status'),
          sortable: false,
          width: '110px'
        }
      ]

      if (this.changeTicketActive) {
        let fieldChangeTicketCheckbox = {
          key: 'changeTicketForTTU',
          label: 'changeTicketForTTU',
          sortable: false,
          width: '50px',
          thClass: 'text-center',
          tdClass: 'text-center'
        }
        fields.splice(0, 0, fieldChangeTicketCheckbox)
      }

      return fields
    },
    visibleSync: {
      get: function () {
        return this.visible
      },
      set: function (newValue) {
        if (!newValue) {
          this.changeTicketActive = false
          this.newTicket = null
          this.ticketSuggestionQuery = ''
          this.ticketSuggestionList = this.recentTickets
        }
        this.$emit('update:visible', newValue)
      }
    },
    totalDuration() {
      let durations = this.ttus.map(ttu => {
        return ttu.durationInMinutes
      })
      let totalDuration = durations.reduce((aggregation, currentValue) => aggregation + currentValue, 0)
      return LocalDateTimeFormatter.durationInMinutesToTime(totalDuration)
    },
    totalBillableDuration() {
      let billableDurations = this.ttus.map(ttu => {
        return this.getBillableOrNormalDuration(ttu.durationInMinutes, ttu.reviewTimeTrackingUnit.timeFactor)
      })
      let totalBillableDuration = billableDurations.reduce((aggregation, currentValue) => aggregation + currentValue, 0)
      return LocalDateTimeFormatter.durationInMinutesToTime(totalBillableDuration)
    }
  },
  methods: {
    editingIsDisabled(row) {
      return this.changeTicketActive || row.item.status === 'RUNNING'
    },
    getRecentTickets() {
      let self = this
      this.ticketSuggestionList = []
      this.ticketSuggestionQuery = ''
      this.getRequest(
        '/tickets/recent',
        new RequestConfig().onSuccess(res => {
          self.recentTickets = res.data
          self.ticketSuggestionList = res.data
        })
      )
    },
    toggleChangeTicketMode() {
      this.ttuIdsToChangeTicket.splice(0)
      this.newTicket = null
      this.ticketSuggestionQuery = ''
      this.changeTicketActive = !this.changeTicketActive
    },
    ticketDropdownSerializer(ticket) {
      return (
        (ticket.ticketId !== null ? ticket.ticketId + ' ' : '') +
        ticket.name +
        ' (' +
        ticket.project.fullName +
        ' / ' +
        ticket.project.customer.fullName +
        ')'
      )
    },
    ticketSuggestionDebouncer() {
      this.ticketSuggestionList = []
      this.getTicketSuggestionDebounced()
    },
    getTicketSuggestionDebounced: debounce(function () {
      this.getTicketSuggestions()
    }, 400),
    getTicketSuggestions() {
      let self = this
      const typeaheadInput = this.ticketSuggestionQuery
      if (typeaheadInput.length >= 3) {
        this.getRequest(
          `/tickets/search_fulltext?query=${encodeURIComponent(typeaheadInput)}&onlyOpen=true`,
          new RequestConfig().onSuccess(res => {
            self.ticketSuggestionList = res.data
          })
        )
      } else if (typeaheadInput.length === 0) {
        self.ticketSuggestionList = self.recentTickets
        self.newTicket = null
      }
    },
    changeTicketForTTUs() {
      if (this.newTicket) {
        let self = this
        let body = JSON.stringify(this.ttuIdsToChangeTicket)
        let ticketId = Number(this.newTicket.id)
        return this.postRequest(
          `/timetracking_units/changeTicket?newTicketId=${ticketId}`,
          body,
          new RequestConfig().onSuccess(() => {
            self.visibleSync = false
            self.displaySuccess('TTUs assigned')
            self.ticketSuggestionQuery = ''
            self.newTicket = null
            self.changeTicketActive = false
            self.$emit('reload-table')
          })
        )
      }
    },
    addOrRemoveTtuId(toBeAdded, id) {
      let isIncluded = this.ttuIdsToChangeTicket.includes(id)
      if (!isIncluded && toBeAdded) {
        this.ttuIdsToChangeTicket.push(id)
      } else if (isIncluded && !toBeAdded) {
        this.ttuIdsToChangeTicket.forEach((ttuId, index) => {
          if (ttuId === id) {
            this.ttuIdsToChangeTicket.splice(index, 1)
          }
        })
      }
    },
    setReviewStatus(status) {
      if (this.ttus) {
        // we are in the new controlling modal
        this.ttus.forEach(ttu => {
          if (ttu.status === 'FINISHED') {
            ttu.reviewStatus = status
            ttu.reviewTimeTrackingUnit.reviewStatus = status
          }
        })
      } else {
        // we are in the legacy validation modal
        this.ttus.forEach(ttu => {
          if (ttu.status === 'FINISHED') {
            ttu.reviewStatus = status
          }
        })
      }
    },
    formatToLocaleDate(datetime) {
      return LocalDateTimeFormatter.formatLocaleDate(datetime)
    },
    formatToTime(datetime) {
      return LocalDateTimeFormatter.toTime(datetime)
    },
    durationInMinutesToTime(durationInMinutes) {
      return LocalDateTimeFormatter.durationInMinutesToTime(durationInMinutes)
    },
    toggleReviewStatus(row) {
      let type = row.reviewStatus
      switch (type) {
        case null:
          row.reviewStatus = 'REVIEWED'
          break
        case 'UNREVIEWED':
          row.reviewStatus = 'REVIEWED'
          break
        case 'REVIEWED':
          row.reviewStatus = 'HIGHLIGHTED'
          break
        case 'HIGHLIGHTED':
          row.reviewStatus = 'BILLED'
          break
        case 'BILLED':
          row.reviewStatus = null
          break
      }
    },
    getBillableOrNormalDurationInTimeFormat(originalDurationInMinutes, timeFactor) {
      let duration = this.getBillableOrNormalDuration(originalDurationInMinutes, timeFactor)
      return LocalDateTimeFormatter.durationInMinutesToTime(duration)
    },
    getBillableOrNormalDuration(originalDurationInMinutes, timeFactor) {
      let duration = 0
      if (timeFactor) {
        duration = originalDurationInMinutes * timeFactor
      } else {
        duration = originalDurationInMinutes
      }
      if (duration < 0) {
        duration = 0
      }
      return duration
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.changeTicketActive && this.ttuIdsToChangeTicket.includes(item.id)) return 'table-primary'
      if (this.changeTicketActive) return ''
      if (item.reviewTimeTrackingUnit.reviewStatus === 'HIGHLIGHTED') return 'table-danger'
      if (item.reviewTimeTrackingUnit.reviewStatus === 'REVIEWED') return 'table-info'
      if (item.reviewTimeTrackingUnit.reviewStatus === 'BILLED') return 'table-success'
    },
    createReviewDtoJSON() {
      let reviewTTUsJSON = []
      this.ttus.forEach(ttu => {
        if (ttu.status === 'FINISHED') {
          reviewTTUsJSON.push({
            durationInMinutes: ttu.durationInMinutes * ttu.reviewTimeTrackingUnit.timeFactor,
            matchingTimeTrackingUnitId: ttu.id,
            ticket: ttu.ticket,
            reviewComment: ttu.reviewTimeTrackingUnit.reviewComment,
            reviewStatus: ttu.reviewTimeTrackingUnit.reviewStatus,
            timeFactor: ttu.reviewTimeTrackingUnit.timeFactor * 100
          })
        }
      })
      return reviewTTUsJSON
    },
    saveReviewedTimeTrackingUnits() {
      const reviewTimeTrackingUnitsJSON = this.createReviewDtoJSON()
      let self = this
      this.postRequest(
        '/review_timetracking_units/save',
        reviewTimeTrackingUnitsJSON,
        new RequestConfig().onSuccess(() => {
          this.visibleSync = false
          self.displaySuccess(self.$t('validation.review-saved-success'))
          this.$emit('reload-table')
        })
      )
    }
  }
}
</script>

<style>
.text-black-30 {
  color: rgba(0, 0, 0, 0.3) !important;
}
</style>
