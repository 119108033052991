<template>
  <b-modal
    v-model="visibleSync"
    size="xxl"
    centered
    :title="$t('validation.review-ttu')"
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-bg-variant="footerBgVariant"
    :footer-text-variant="footerTextVariant"
  >
    <b-container fluid>
      <b-input-group class="mb-3" :prepend="$t('validation.change-review-status-all-items')" size="sm">
        <b-button
          v-for="button in reviewStatusButtons"
          :key="button.text"
          class="ml-2"
          size="sm"
          @click="setReviewStatus(button.value)"
          >{{ button.text }}</b-button
        >
      </b-input-group>
      <b-table
        id="validation-review-datatable"
        show-empty
        small
        fixed
        stacked="xl"
        hover="hover"
        :tbody-tr-class="rowClass"
        :items="reviewTTUs"
        :fields="fields"
        :current-page="reviewTableControls.currentPage"
        :per-page="reviewTableControls.perPage"
        :sort-by="reviewTableControls.sortBy"
        :sort-desc="true"
        striped
        @row-selected="onRowSelect"
        ref="reviewTable"
        head-variant="light"
      >
        <template v-slot:cell(ticket.project.customer.fullName)="row">
          <span v-ellipsis:bottom="row.value" size="sm">{{ row.item.ticket.project.customer.fullName }}</span>
        </template>
        <template v-slot:cell(projectName)="row">
          <span v-ellipsis:bottom="row.value" size="sm">{{ row.item.projectName }}</span>
        </template>
        <template v-slot:cell(ticket.name)="row">
          <b-form-input v-model="row.item.ticket.name" class="review-input" size="sm"></b-form-input>
        </template>
        <template v-slot:cell(ticket.ticketId)="row">
          <b-form-input v-model="row.item.ticket.ticketId" class="review-input" size="sm"></b-form-input>
        </template>
        <template v-slot:cell(timeTrackingUnit.user.fullName)="row">
          <span v-ellipsis:bottom="row.value" size="sm">{{ row.item.userName }}</span>
        </template>
        <template v-slot:cell(timeFactor)="row">
          <b-form-input v-model="row.item.timeFactor" class="review-input w-50" size="sm" type="number" step="0.01" />
        </template>
        <template v-slot:cell(durationInMinutes)="row">
          <div>{{ getDurationInTimeFormat(row.item.originalDurationInMinutes, row.item.timeFactor) }}</div>
        </template>
        <template v-slot:cell(comment)="row">
          <span v-ellipsis:bottom="row.value" size="sm">{{ row.item.comment }}</span>
        </template>
        <template v-slot:cell(reviewComment)="row">
          <b-form-input v-model="row.item.reviewComment" class="review-input" size="sm"></b-form-input>
        </template>
        <template v-slot:cell(reviewStatus)="row">
          <b-button v-model="row.item.reviewStatus" class="w-100" size="sm" @click="toggleReviewStatus(row.item)">
            {{ row.item.reviewStatus === null ? 'UNREVIEWED' : row.item.reviewStatus }}</b-button
          >
        </template>
      </b-table>
      <div class="overflow-auto mb-4">
        <b-pagination
          v-model="reviewTableControls.currentPage"
          :total-rows="reviewTTUs.length"
          :per-page="reviewTableControls.perPage"
          class="mt-2"
        ></b-pagination>
      </div>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button class="float-right" variant="primary" @click="saveReviewedTimeTrackingUnits">
          {{ $t('general.save') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'

export default {
  name: 'ControllingReviewModal',
  mixins: [ApiMixin, NotificationMixin],
  props: {
    reviewTTUs: {
      required: true,
      type: Array
    },
    visible: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reviewTableControls: {
        selected: '',
        totalRows: 1,
        currentPage: this.$route.params.pageNumber ? this.$route.params.pageNumber : 1,
        perPage: 50,
        sortBy: 'startDate'
      },
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      reviewStatusButtons: StaticSelectOpts.reviewStatusOptions
    }
  },
  computed: {
    fields() {
      return [
        { key: 'ticket.project.customer.fullName', label: this.$t('timetracking.customer'), sortable: true },
        { key: 'projectName', label: this.$t('validation.project'), sortable: true },
        { key: 'ticket.ticketId', label: 'Ticket-Id', sortable: true },
        { key: 'ticket.name', label: 'Ticket-Name', sortable: true },
        { key: 'timeTrackingUnit.user.fullName', label: this.$t('validation.user'), sortable: true },
        { key: 'timeFactor', label: this.$t('validation.time-factor'), sortable: false },
        {
          key: 'durationInMinutes',
          label: this.$t('validation.billable'),
          sortable: false,
          formatter: 'durationInMinutesToTime'
        },
        { key: 'comment', label: this.$t('validation.comment'), sortable: false },
        { key: 'reviewComment', label: this.$t('validation.review-comment'), sortable: false },
        { key: 'reviewStatus', label: this.$t('validation.save-ttu-as'), sortable: false }
      ]
    },
    visibleSync: {
      get: function () {
        return this.visible
      },
      set: function (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    setReviewStatus(status, listOfTTUs) {
      if (listOfTTUs) {
        // we are in the new controlling modal
        listOfTTUs.forEach(ttu => {
          ttu.reviewStatus = status
          ttu.reviewTimeTrackingUnit.reviewStatus = status
        })
      } else {
        // we are in the legacy validation modal
        this.reviewTTUs.forEach(ttu => {
          ttu.reviewStatus = status
        })
      }
    },
    onRowSelect(items) {
      this.selectedRows = items
    },
    getDurationInTimeFormat(durationInMinutes, timeFactor) {
      // durationInMinutes already contains the net duration excluding the break duration
      let duration = durationInMinutes * timeFactor
      if (duration < 0) {
        duration = 0
      }
      return LocalDateTimeFormatter.durationInMinutesToTime(duration)
    },
    toggleReviewStatus(row) {
      let type = row.reviewStatus
      switch (type) {
        case null:
          row.reviewStatus = 'REVIEWED'
          break
        case 'UNREVIEWED':
          row.reviewStatus = 'REVIEWED'
          break
        case 'REVIEWED':
          row.reviewStatus = 'HIGHLIGHTED'
          break
        case 'HIGHLIGHTED':
          row.reviewStatus = 'BILLED'
          break
        case 'BILLED':
          row.reviewStatus = null
          break
      }
    },
    saveReviewedTimeTrackingUnits() {
      const reviewTimeTrackingUnitsJSON = this.createReviewDtoJSON()
      let self = this
      this.postRequest(
        '/review_timetracking_units/save',
        reviewTimeTrackingUnitsJSON,
        new RequestConfig().onSuccess(() => {
          self.visibleSync = false
          self.displaySuccess(self.$t('validation.review-saved-success'))
          self.reviewTTUs.forEach(reviewTTU => {
            let ttu = reviewTTU.timeTrackingUnit
            delete reviewTTU.timeTrackingUnit
            ttu.reviewTimeTrackingUnit = reviewTTU
          })
          self.reloadTable()
        })
      )
    },
    createReviewDtoJSON() {
      let reviewTTUsJSON = []
      this.reviewTTUs.forEach(ttu => {
        reviewTTUsJSON.push({
          durationInMinutes: ttu.originalDurationInMinutes * ttu.timeFactor,
          matchingTimeTrackingUnitId: ttu.matchingTimeTrackingUnitId,
          ticket: ttu.ticket,
          reviewComment: ttu.reviewComment,
          reviewStatus: ttu.reviewStatus,
          timeFactor: ttu.timeFactor * 100
        })
      })
      return reviewTTUsJSON
    },
    reloadTable() {
      this.$emit('reload-table')
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.reviewStatus === 'HIGHLIGHTED') return 'table-danger'
      if (item.reviewStatus === 'REVIEWED') return 'table-info'
      if (item.reviewStatus === 'BILLED') return 'table-success'
    }
  }
}
</script>

<style scoped></style>

<style lang="scss">
.review-input {
  background-color: transparent;
  border-color: #bbb;

  &:hover,
  &:focus {
    color: black;
    border-color: var(--secondary);
  }
}
</style>
