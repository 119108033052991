<template>
  <b-container id="controlling-tab-content-container" fluid class="p-1">
    <b-row id="controlling-filter" align-h="start" align-v="end" class="mx-0 mb-3">
      <b-col cols="2" xl="1" class="px-0">
        <b-form-select v-model="filters.year" :options="years" />
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <b-form-select v-model="filters.firstWeek" :options="weeksOfTheYear" class="w-100" />
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <b-form-select v-model="filters.lastWeek" :options="remainingWeeksOfTheYear" class="w-100" />
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="customerQuery"
          :section-configs="{ default: { limit: 6 } }"
          :suggestions="customerSuggestions"
          :input-props="{ id: 'customerSearch', placeholder: this.$t('validation.search-customer') }"
          @selected="selectCustomerHandler"
          @input="resetCustomerFilter"
          @change="unselectIfEmpty('customer')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="projectQuery"
          :section-configs="{ default: { limit: 6 } }"
          :suggestions="projectSuggestions"
          :input-props="{ id: 'projectSearch', placeholder: this.$t('validation.search-project') }"
          @selected="selectProjectHandler"
          @input="resetProjectFilter"
          @change="unselectIfEmpty('project')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="2" class="ml-auto px-0 pr-1" v-if="isAdmin">
        <span id="disabled-wrapper" class="float-right" tabindex="0">
          <b-overlay :show="exportLoading" class="float-right" spinner-small spinner-variant="secondary">
            <b-button
              id="export-button"
              class="w-90 float-right"
              :disabled="!allFiltersSet || multipleWeeksSelected"
              @click="exportData"
              data-placement="bottom"
              >{{ $t('controlling.button-export') }}</b-button
            >
          </b-overlay>
        </span>
        <b-tooltip v-if="!allFiltersSet || multipleWeeksSelected" target="disabled-wrapper" triggers="hover">
          {{ $t('controlling.button-export-tooltip') }}
        </b-tooltip>
      </b-col>
    </b-row>

    <b-card>
      <p class="text-center m-0" v-if="messages.default">{{ $t('controlling.table.default') }}</p>
      <p class="text-center m-0" v-if="messages.emptyFiltered">
        {{ $t('controlling.table.empty-filtered-text') }}
      </p>
      <p class="text-center m-0" v-if="messages.loading">{{ $t('controlling.table.loading') }}</p>
      <p class="text-center m-0" v-if="messages.error">{{ $t('controlling.table.error') }}</p>

      <div
        v-for="week in tableData"
        :key="week.name"
        :class="{ 'mb-5': tableData.length > 1, 'controlling-row': true, 'week-controlling-row': true }"
      >
        <ControllingWeekTableWrapper
          :aggregation-object="week"
          :year="filters.year"
          @row-click="emitTicketInfo(week, ...arguments)"
          @project-header-row-click="emitCustomerProjectInfo(week, ...arguments)"
          @project-cost-group-header-row-click="emitCustomerProjectCostGroupInfo(week, ...arguments)"
          @customer-header-row-click="emitCustomerInfo(week, ...arguments)"
          @time-header-row-click="emitTimeInfo"
        />
      </div>
    </b-card>
    <ControllingColorKey class="mt-3" />
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { LocalDate, StaticDates } from '@/util/LocalDateTimeFormatter'
import { VueAutosuggest } from 'vue-autosuggest'
import ControllingColorKey from '@/components/controlling/ControllingColorKey'
import ControllingWeekTableWrapper from './tables/ControllingWeekTableWrapper.vue'

export default {
  name: 'ControllingWeekTab',
  mixins: [ApiMixin, NotificationMixin],
  components: {
    ControllingWeekTableWrapper,
    VueAutosuggest,
    ControllingColorKey
  },
  props: {
    years: {
      required: true,
      type: Array
    },
    reloadDataProp: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      tableData: [],
      filters: {
        year: LocalDate.currentYear(),
        firstWeek: LocalDate.currentWeek(),
        lastWeek: LocalDate.currentWeek(),
        customer: '',
        project: ''
      },
      weeksOfTheYear: LocalDate.weeksInYear(LocalDate.currentYear()),
      remainingWeeksOfTheYear: LocalDate.remainingWeeksInCurrentYear(LocalDate.currentYear(), LocalDate.currentWeek()),
      months: StaticDates.monthListAsSelectArray(),
      customerQuery: '',
      projectQuery: '',
      messages: {
        default: true,
        loading: false,
        emptyFiltered: false,
        error: false
      },
      exportLoading: false
    }
  },
  watch: {
    reloadDataProp: function () {
      this.reloadWeeklyData()
    },
    'filters.firstWeek': function () {
      if (this.filters.firstWeek !== this.filters.lastWeek) {
        this.updateLastWeekSelect()
        // triggers 'filters.lastWeek' watcher and therefore also a table reload
      } else {
        this.reloadWeeklyData()
      }
    },
    'filters.lastWeek': function () {
      this.reloadWeeklyData()
    },
    'filters.customer': function () {
      this.reloadWeeklyData()
    },
    'filters.project': function () {
      this.reloadWeeklyData()
    }
  },
  mounted() {
    this.fetchWeeklyData()
  },
  computed: {
    allFiltersSet() {
      return (
        this.filters.year !== null &&
        this.filters.firstWeek !== null &&
        this.filters.lastWeek !== null &&
        this.filters.customer !== null &&
        this.filters.customer !== '' &&
        this.filters.project !== null &&
        this.filters.project !== ''
      )
    },

    projectSuggestions() {
      if (this.tableData.length === 0) {
        return []
      } else {
        const suggestions = []
        this.tableData.forEach(week => {
          week.childAggregations.forEach(customer => {
            customer.childAggregations.forEach(project => {
              suggestions.push(project.name)
            })
          })
        })
        const filteredSuggestions = suggestions.filter(item => {
          return item.toLowerCase().startsWith(this.projectQuery.toLowerCase())
        })
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    },
    customerSuggestions() {
      if (this.tableData.length === 0) {
        return []
      } else {
        const suggestions = []
        this.tableData.forEach(week => {
          week.childAggregations.forEach(customer => {
            suggestions.push(customer.name)
          })
        })
        const filteredSuggestions = suggestions.filter(item => {
          return item.toLowerCase().startsWith(this.customerQuery.toLowerCase())
        })
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    },
    multipleWeeksSelected() {
      return this.filters.firstWeek !== this.filters.lastWeek
    }
  },
  methods: {
    emitCustomerProjectInfo(week, element) {
      element.year = this.filters.year
      element.week = week.name
      this.$emit('project-header-row-click', element)
    },
    emitCustomerProjectCostGroupInfo(week, element) {
      element.year = this.filters.year
      element.week = week.name
      this.$emit('project-cost-group-header-row-click', element)
    },
    emitCustomerInfo(week, element) {
      element.year = element.year ? element.year : this.filters.year
      element.week = week.name
      this.$emit('customer-header-row-click', element)
    },
    emitTimeInfo(timePeriod) {
      timePeriod.year = timePeriod.year ? timePeriod.year : this.filters.year
      timePeriod.title = this.weeksOfTheYear[timePeriod.week - 1].text
      this.$emit('time-header-row-click', timePeriod)
    },
    updateRemainingWeeks() {
      this.remainingWeeksOfTheYear = LocalDate.remainingWeeksInCurrentYear(this.filters.year, this.filters.firstWeek)
    },
    emitTicketInfo(week, object) {
      this.$emit('row-click', { ticket: object, week: week.name, year: this.filters.year, month: '', user: '' })
    },
    reloadWeeklyData() {
      // TIM-651 don't reload if a reload is already in progress.
      // This is a guard for when both datepicker start and end dates are changed from a DatepickerQuickfilter component.
      if (this.messages.loading) {
        console.debug(
          'Table reload was triggered but another reload is already in progress. Aborting this reload request!'
        )
      } else {
        console.debug('Table reload was triggered')
        this.fetchWeeklyData()
      }
    },
    fetchWeeklyData() {
      this.messages.default = false
      this.messages.emptyFiltered = false
      this.messages.loading = true
      let weeks = [this.filters.firstWeek, this.filters.lastWeek]
      let year = this.filters.year
      let customerName = this.filters.customer
      let projectName = this.filters.project
      let self = this
      self.tableData = []
      return this.getRequest(
        `/table/controlling/week?year=${year}&weeks=${weeks}&customerName=${customerName}&projectName=${projectName}`,
        new RequestConfig()
          .onSuccess(res => {
            self.tableData = res.data
            self.messages.loading = false
            self.messages.error = false
            if (self.tableData.length === 0) {
              self.messages.emptyFiltered = true
            } else {
              self.messages.emptyFiltered = false
            }
          })
          .onError(() => {
            self.tableData = []
            self.messages.loading = false
            self.messages.emptyFiltered = false
            self.messages.error = true
          })
      )
    },
    updateLastWeekSelect() {
      this.filters.lastWeek = this.filters.firstWeek
      this.updateRemainingWeeks()
    },
    selectCustomerHandler(item) {
      if (!item) {
        this.customerQuery = ''
      } else {
        this.customerQuery = item.item
      }
      this.filters.customer = this.customerQuery
    },
    selectProjectHandler(item) {
      if (!item) {
        this.projectQuery = ''
      } else {
        this.projectQuery = item.item
      }
      this.filters.project = this.projectQuery
    },
    resetCustomerFilter() {
      this.filters.customer = ''
    },
    resetProjectFilter() {
      this.filters.project = ''
    },
    unselectIfEmpty(type) {
      switch (type) {
        case 'customer':
          if (this.customerQuery === '') {
            this.resetCustomerFilter()
          }
          break
        case 'project':
          if (this.projectQuery === '') {
            this.resetProjectFilter()
          }
          break
      }
    },

    exportData() {
      this.messages.default = false
      this.messages.emptyFiltered = false
      this.exportLoading = true
      let week = this.filters.firstWeek
      let year = this.filters.year
      let customerName = this.filters.customer
      let projectName = this.filters.project
      let self = this
      let url = `/export/controlling/weekly?year=${year}&week=${week}&customerName=${customerName}&projectName=${projectName}`
      return this.postRequest(
        url,
        null,
        new RequestConfig()
          .withAxiosConfig({ responseType: 'arraybuffer' })
          .onSuccess(res => {
            const blob = new Blob([res.data], { type: 'text/csv' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            // parse filename out of content-dispo header, default file.csv
            link.download = 'file.csv'
            const disposition = res.headers['content-disposition']
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              const matches = filenameRegex.exec(disposition)
              if (matches != null && matches[1]) {
                link.download = matches[1].replace(/['"]/g, '')
              }
            }
            link.click()
            URL.revokeObjectURL(link.href)
            link.remove()
            this.exportLoading = false
          })
          .onError(err => {
            // err.response.data is an arraybuffer because of the request response type, need to change it to string
            const res = JSON.parse(Buffer.from(err.response.data).toString('utf8'))
            if (res.message === 'missing-review-timetracking-units') {
              self.displayError(self.$t('export.missing-review-timetracking-units'))
            } else {
              self.displayError(res.message)
            }
            this.exportLoading = false
          })
      )
    }
  }
}
</script>

<style scoped></style>
