<template>
  <div>
    <b-row align-h="between" align-v="end" class="mt-2 controlling-row-header">
      <b-col cols="4">
        <div class="row align-middle align-items-center">
          <h4 class="mb-2 ml-3 mt-2">
            {{
              aggregationObject.type === 'month'
                ? parseMonth(aggregationObject.name)
                : aggregationObject.type === 'week'
                ? weeksOfTheYear[aggregationObject.name - 1].text
                : aggregationObject.name
            }}
          </h4>
          <ControllingReviewHeaderButton class="ml-3 mt-2" :element="{}" @header-row-click="emitTimeInfo" />
        </div>
      </b-col>
      <b-col />
      <b-col cols="1">
        <b-form-text
          >{{ $t('validation.totalDuration') }}:
          <strong>{{ durationInMinutesToTime(totalDuration()) }}</strong></b-form-text
        >
      </b-col>
      <b-col cols="1">
        <b-form-text class="text-right"
          >{{ $t('controlling.billable') }}:
          <strong>{{ durationInMinutesToTime(totalBillableDuration()) }}</strong></b-form-text
        >
      </b-col>
    </b-row>

    <b-table class="mb-0" small fixed hover="hover" thead-class="hidden_header" :fields="headFields" :items="[]">
      <template #bottom-row>
        <td style="width: 15%" class="font-weight-bold text-muted">
          {{ $t('general.customer') }}
        </td>
        <td style="width: 60%" class="font-weight-bold text-muted">
          {{ $t('controlling.cost-center') }}
        </td>
        <td class="font-weight-bold text-right text-muted">
          {{ $t('controlling.duration') }}
        </td>
        <td class="font-weight-bold text-right text-muted">
          {{ $t('controlling.billable') }}
        </td>
      </template>
    </b-table>

    <div v-for="customer in aggregationObject.childAggregations" :key="customer.name" class="ml-1 mr-0">
      <ControllingThreeLevelInnerTable
        :rootElement="customer"
        @row-click="emitTicketInfo"
        @project-header-row-click="emitCustomerProjectInfo"
        @customer-header-row-click="emitCustomerInfo"
      />
    </div>
  </div>
</template>

<script>
import { LocalDate, LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import ControllingThreeLevelInnerTable from '@/components/controlling/tables/ControllingThreeLevelInnerTable'
import ControllingReviewHeaderButton from '@/components/controlling/ControllingReviewHeaderButton'

export default {
  name: 'ControllingCustomerTableWrapper',
  components: {
    ControllingThreeLevelInnerTable,
    ControllingReviewHeaderButton
  },
  props: {
    aggregationObject: {
      type: Object,
      required: true
    },
    year: {
      type: Number,
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {
    headFields() {
      return [
        { key: 'customer', label: this.$t('general.customer'), sortable: false },
        { key: 'costCenter', label: this.$t('controlling.cost-center'), sortable: false },
        { key: 'duration', label: this.$t('controlling.duration'), sortable: false },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false }
      ]
    },
    weeksOfTheYear() {
      return LocalDate.weeksInYear(this.year)
    }
  },
  methods: {
    emitTicketInfo(ticket) {
      this.$emit('row-click', ticket)
    },
    emitCustomerProjectInfo(element) {
      this.$emit('project-header-row-click', element)
    },
    emitCustomerInfo(element) {
      this.$emit('customer-header-row-click', element)
    },
    emitTimeInfo() {
      let emitObject = {}
      if (this.aggregationObject.type === 'week') {
        emitObject.week = this.aggregationObject.name
      } else if (this.aggregationObject.type === 'month') {
        emitObject.month = this.aggregationObject.name
      }
      emitObject.year = this.aggregationObject.year
      this.$emit('time-header-row-click', emitObject)
    },
    durationInMinutesToTime(minutes) {
      return LocalDateTimeFormatter.durationInMinutesToTime(minutes)
    },
    parseMonth(month) {
      return LocalDate.parseMonth(month)
    },
    totalDuration() {
      let totalDuration = 0
      this.aggregationObject.childAggregations.forEach(customer => {
        let customerDuration = 0
        customer.childAggregations.forEach(project => {
          let projectDuration = 0
          project.details.forEach(ticket => {
            projectDuration = projectDuration + ticket.duration
          })
          customerDuration = customerDuration + projectDuration
        })
        totalDuration = totalDuration + customerDuration
      })
      return totalDuration
    },
    totalBillableDuration() {
      let totalDuration = 0
      this.aggregationObject.childAggregations.forEach(customer => {
        let customerDuration = 0
        customer.childAggregations.forEach(project => {
          let projectDuration = 0
          project.details.forEach(ticket => {
            projectDuration = projectDuration + ticket.billableDuration
          })
          customerDuration = customerDuration + projectDuration
        })
        totalDuration = totalDuration + customerDuration
      })
      return totalDuration
    }
  }
}
</script>

<style scoped></style>
