<template>
  <b-table
    class="mb-0 controlling-single-month-table"
    small
    fixed
    hover="hover"
    thead-class="hidden_header"
    :fields="fields"
    :items="[]"
  >
    <template #top-row>
      <td style="width: 15%" class="pl-0 align-bottom font-weight-bold align-items-center">
        {{ customer.name }}
        <ControllingReviewHeaderButton class="ml-3" :element="{}" @header-row-click="emitCustomerInfo" />
      </td>
      <td style="width: 85%" class="align-bottom">
        <AggregatedDurationTableCustomer :customer="customer.childAggregations" />
      </td>
    </template>
    <template #bottom-row>
      <td class="px-0" />
      <td class="px-0">
        <div v-for="project in customer.childAggregations" :key="project.name" class="controlling-row-single-customer">
          <ControllingThreeLevelInnerTable
            :rootElement="project"
            @row-click="emitTicketInfo(project, ...arguments)"
            @project-header-row-click="emitCustomerProjectProjectCostGroupInfo"
            @customer-header-row-click="emitCustomerProjectInfo"
            @cost-group-header-row-click="emitCustomerProjectProjectCostGroupInfo"
          />
        </div>
      </td>
    </template>
  </b-table>
</template>
<script>
import ControllingReviewHeaderButton from '../ControllingReviewHeaderButton.vue'
import ControllingThreeLevelInnerTable from './ControllingThreeLevelInnerTable.vue'
import AggregatedDurationTableCustomer from './AggregatedDurationTableCustomer.vue'
export default {
  name: 'ControllingWeekTable',
  components: { ControllingReviewHeaderButton, ControllingThreeLevelInnerTable, AggregatedDurationTableCustomer },
  props: {
    customer: {
      required: true,
      type: Object
    }
  },
  computed: {
    fields() {
      return [
        { key: 'inputObject', label: '', sortable: false, width: '70%' },
        { key: 'projectTable', label: '', sortable: false }
      ]
    }
  },
  methods: {
    emitTicketInfo(project, ticketCostGroupInfo) {
      ticketCostGroupInfo.projectName = project.name
      this.$emit('row-click', ticketCostGroupInfo)
    },
    emitCustomerProjectInfo(element) {
      let emitObject = { project: element.customer, year: element.year, customer: this.customer.name }
      this.$emit('project-header-row-click', emitObject)
    },
    emitCustomerProjectProjectCostGroupInfo(element) {
      this.$emit('project-cost-group-header-row-click', element)
    },
    emitCustomerInfo() {
      let emitObject = { customer: this.customer.name }
      this.$emit('customer-header-row-click', emitObject)
    }
  }
}
</script>
