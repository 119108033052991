<template>
  <b-container id="controlling-tab-content-container" fluid class="p-1">
    <b-row id="controlling-filter" align-h="start" align-v="end" class="mx-0 mb-3">
      <b-col cols="1" class="px-0">
        <vue-autosuggest
          v-model="customerQuery"
          :suggestions="customerSuggestions"
          :input-props="{ id: 'customerSearch', placeholder: this.$t('validation.search-customer') }"
          @selected="selectCustomerHandler"
          @input="resetCustomerFilter"
          @change="unselectIfEmpty('customer')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="projectQuery"
          :suggestions="projectSuggestions"
          :input-props="{ id: 'projectSearch', placeholder: this.$t('validation.search-project') }"
          @selected="selectProjectHandler"
          @input="resetProjectFilter"
          @change="unselectIfEmpty('project')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <b-form-select v-model="filters.year" :options="years">
          <b-form-select-option :value="''">{{ $t('datetime.year.all') }}</b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>

    <b-card>
      <ControllingCustomerGenericTable
        :aggregation-object="aggregationObject"
        :head-fields="headFields"
        :table-data="tableData"
        :cost-center="$t('general.project')"
        :header="$t('controlling.project-overview')"
        :state="stateObject"
        @row-click="emitProjectInfo"
      />
    </b-card>

    <b-card class="mt-3">
      <ControllingCustomerGenericTable
        :aggregation-object="aggregationTicketObject"
        :head-fields="headFieldsTickets"
        :table-data="tableDataTicket"
        :cost-center="$t('general.ticket')"
        :header="$t('controlling.ticket-overview')"
        :state="stateObjectTickets"
        :is-ticket-overview="true"
        @row-click="emitTicketInfo"
      />
    </b-card>
    <ControllingColorKey class="mt-3" />
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { VueAutosuggest } from 'vue-autosuggest'
import ControllingCustomerGenericTable from '@/components/controlling/tables/ControllingCustomerGenericTable'
import { isEmpty as _isEmpty } from 'lodash'
import ControllingColorKey from '@/components/controlling/ControllingColorKey'
import { LocalDate } from '@/util/LocalDateTimeFormatter'

export default {
  name: 'ControllingCustomerTab',
  mixins: [ApiMixin, NotificationMixin],
  components: { ControllingCustomerGenericTable, VueAutosuggest, ControllingColorKey },
  props: {
    years: {
      required: true,
      type: Array
    },
    customers: {
      required: true,
      type: Array
    },
    projects: {
      required: false,
      type: Array
    },
    reloadDataProp: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      tableData: [],
      tableDataTicket: [],
      aggregationObject: {
        type: 'customer',
        name: '',
        childAggregations: null
      },
      aggregationTicketObject: {
        type: 'customer',
        name: '',
        childAggregations: null
      },
      filters: {
        year: LocalDate.currentYear(),
        customer: '',
        project: ''
      },
      customerQuery: '',
      projectQuery: '',
      reviewTableControls: {
        selected: '',
        totalRows: 1,
        currentPage: this.$route.params.pageNumber ? this.$route.params.pageNumber : 1,
        perPage: 50,
        sortBy: 'startDate'
      },
      stateObject: {
        default: true,
        loading: false,
        loaded: false,
        emptyFiltered: false,
        error: false
      },
      stateObjectTickets: {
        default: true,
        loading: false,
        loaded: false,
        emptyFiltered: false,
        noProject: false,
        error: false
      }
    }
  },
  watch: {
    reloadDataProp: function () {
      if (this.filters.customer !== '' || this.filters.project !== '') {
        this.loadCustomerData()
        this.tableDataTicket.splice(0)
        if (this.filters.project !== '') {
          this.loadCustomerTicketData()
        } else {
          this.stateObjectTickets.noProject = true
        }
      }
    },
    filters: {
      handler() {
        if (this.filters.customer !== '' || this.filters.project !== '') {
          this.loadCustomerData()
          this.tableDataTicket.splice(0)
          if (this.filters.project !== '') {
            this.loadCustomerTicketData()
          } else {
            this.stateObjectTickets.noProject = true
          }
        }
        if (this.filters.customer === '' && this.filters.project === '') {
          this.tableData = []
          this.stateObject.emptyFiltered = true
          this.tableDataTicket = []
          this.stateObjectTickets.emptyFiltered = true
        }
      },
      deep: true
    }
  },
  computed: {
    isCustomerSet() {
      return this.filters.customer !== null && !_isEmpty(this.filters.customer)
    },
    headFields() {
      return [
        { key: 'user', label: 'userName', sortable: false, width: 'auto' },
        { key: 'customer', label: this.$t('general.customer'), sortable: false, width: 'auto' },
        { key: 'projectName', label: this.$t('general.project'), sortable: false, width: 'auto' },
        { key: 'duration', label: this.$t('validation.duration'), sortable: false, width: 'auto' },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false, width: 'auto' }
      ]
    },
    headFieldsTickets() {
      return [
        { key: 'user', label: 'userName', sortable: false, width: 'auto' },
        { key: 'customer', label: this.$t('general.customer'), sortable: false, width: 'auto' },
        { key: 'ticketName', label: this.$t('general.ticket'), sortable: false, width: 'auto' },
        { key: 'duration', label: this.$t('validation.duration'), sortable: false, width: 'auto' },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false, width: 'auto' }
      ]
    },
    customerSuggestions() {
      let customerNames = this.customers.map(customer => customer.fullName)
      const filteredSuggestions = customerNames.filter(c => {
        return c.toLowerCase().startsWith(this.customerQuery.toLowerCase())
      })
      return [{ data: filteredSuggestions }]
    },
    projectSuggestions() {
      if (this.tableData.length === 0 && !this.isCustomerSet) {
        let projectNames = this.projects.map(project => project.fullName)
        const filteredSuggestions = this.filterListByQuery(projectNames, this.projectQuery)
        return [{ data: filteredSuggestions }]
      } else {
        const suggestions = this.tableData.map(project => project.name)
        const filteredSuggestions = this.filterListByQuery(suggestions, this.projectQuery)
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    }
  },
  methods: {
    filterListByQuery(list, query) {
      return list.filter(element => {
        return element.toLowerCase().startsWith(query.toLowerCase())
      })
    },
    loadCustomerData() {
      this.stateObject.default = false
      this.stateObject.emptyFiltered = false
      this.stateObject.loading = true
      this.stateObject.loaded = false
      let customerName = this.filters.customer
      let projectName = this.filters.project
      let year = this.filters.year
      this.tableData = []
      let self = this
      return this.getRequest(
        `/table/controlling/customer?year=${year}&customerName=${customerName}&projectName=${projectName}`,
        new RequestConfig()
          .onSuccess(res => {
            self.tableData = res.data
            self.aggregationObject.name = self.filters.customer
            self.aggregationObject.childAggregations = res.data
            self.stateObject.loaded = true
            self.stateObject.loading = false
            self.stateObject.error = false
            if (self.tableData.length === 0) {
              self.stateObject.emptyFiltered = true
            } else {
              self.stateObject.emptyFiltered = false
            }
          })
          .onError(() => {
            self.tableData = []
            self.stateObject.loading = false
            self.stateObject.loaded = false
            self.stateObject.emptyFiltered = false
            self.stateObject.error = true
          })
      )
    },
    loadCustomerTicketData() {
      this.stateObjectTickets.default = false
      this.stateObjectTickets.emptyFiltered = false
      this.stateObjectTickets.loaded = false
      this.stateObjectTickets.noProject = false
      this.stateObjectTickets.loading = true
      let customerName = this.filters.customer
      let projectName = this.filters.project
      let year = this.filters.year
      this.tableDataTicket = []
      let self = this
      return this.getRequest(
        `/table/controlling/customer/ticket?year=${year}&customerName=${customerName}&projectName=${projectName}`,
        new RequestConfig()
          .onSuccess(res => {
            self.tableDataTicket = res.data
            self.aggregationTicketObject.name = self.filters.customer
            self.aggregationTicketObject.childAggregations = res.data
            self.stateObjectTickets.loaded = true
            self.stateObjectTickets.loading = false
            self.stateObjectTickets.error = false
            self.stateObjectTickets.emptyFiltered = self.tableDataTicket.length === 0
          })
          .onError(() => {
            self.tableDataTicket = []
            self.stateObjectTickets.loading = false
            self.stateObjectTickets.loaded = false
            self.stateObjectTickets.emptyFiltered = false
            self.stateObjectTickets.error = true
          })
      )
    },
    emitTicketInfo(object) {
      this.$emit('row-click', {
        ticket: { name: '', id: object.ticketId },
        user: object.userName,
        year: object.year,
        month: '',
        week: '',
        wholeTimespan: false,
        modalTitle: object.modalTitle
      })
    },
    emitProjectInfo(object) {
      if (object.type === 'projectYear') {
        this.$emit('row-click-project', {
          customer: this.aggregationObject.name,
          project: object.project,
          user: '',
          year: object.year,
          modalTitle: object.modalTitle
        })
      } else if (object.customer !== '') {
        this.$emit('row-click-project', {
          customer: object.customer,
          project: object.project,
          user: object.userName,
          year: object.year,
          modalTitle: object.modalTitle
        })
      } else {
        this.makeToast('You need to select A customer first', 'Warning', 'warning')
      }
    },
    selectCustomerHandler(item) {
      // if customer changed, clear project
      if (this.customerQuery === '' || this.filters.customer !== this.customerQuery) {
        this.filters.project = ''
        this.projectQuery = ''
      }
      if (!item) {
        this.customerQuery = ''
      } else {
        this.customerQuery = item.item
      }
      this.filters.customer = this.customerQuery
    },
    selectProjectHandler(item) {
      if (!item) {
        this.projectQuery = ''
      } else {
        this.projectQuery = item.item
      }
      if (this.isCustomerSet) {
        this.filters.project = this.projectQuery
      } else {
        this.filters.project = this.projectQuery
        for (const element of this.projects) {
          if (element.fullName === this.filters.project) {
            this.customerQuery = element.customer.fullName
            this.filters.customer = this.customerQuery
            break
          }
        }
      }
    },
    resetCustomerFilter() {
      this.filters.customer = ''
      this.filters.project = ''
      this.projectQuery = ''
    },
    resetProjectFilter() {
      this.filters.project = ''
    },
    unselectIfEmpty(type) {
      switch (type) {
        case 'customer':
          if (this.customerQuery === '') {
            this.resetCustomerFilter()
          }
          break
        case 'project':
          if (this.projectQuery === '') {
            this.resetProjectFilter()
          }
          break
      }
    }
  }
}
</script>

<style scoped></style>
