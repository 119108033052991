<template>
  <b-container id="controlling-tab-content-container" fluid class="p-1">
    <b-row id="controlling-filter" align-h="start" align-v="end" class="mx-0 mb-3">
      <b-col cols="2" xl="1" class="px-0">
        <b-form-select v-model="filters.year" :options="years" />
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <b-form-select v-model="filters.month" :options="months" />
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="customerQuery"
          :section-configs="{ default: { limit: 6 } }"
          :suggestions="CustomerSuggestions"
          :input-props="{ id: 'customerSearch', placeholder: this.$t('validation.search-customer') }"
          @selected="selectCustomerHandler"
          @input="resetCustomerFilter"
          @change="unselectIfEmpty('customer')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="projectQuery"
          :section-configs="{ default: { limit: 6 } }"
          :suggestions="ProjectSuggestions"
          :input-props="{ id: 'projectSearch', placeholder: this.$t('validation.search-project') }"
          @selected="selectProjectHandler"
          @input="resetProjectFilter"
          @change="unselectIfEmpty('project')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
    </b-row>

    <b-card>
      <p class="text-center m-0" v-if="messages.default">{{ $t('controlling.table.default') }}</p>
      <p class="text-center m-0" v-if="messages.emptyFiltered">
        {{ $t('controlling.table.empty-filtered-text') }}
      </p>
      <p class="text-center m-0" v-if="messages.loading">{{ $t('controlling.table.loading') }}</p>
      <p class="text-center m-0" v-if="messages.error">{{ $t('controlling.table.error') }}</p>

      <ControllingCustomerTableWrapper
        v-if="tableData.length !== 0"
        :aggregation-object="aggregationObject"
        @row-click="emitTicketInfo"
        @project-header-row-click="emitCustomerProjectInfo"
        @customer-header-row-click="emitCustomerInfo"
        @time-header-row-click="emitTimeInfo"
      />
    </b-card>
    <ControllingColorKey class="mt-3" />
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { LocalDate } from '@/util/LocalDateTimeFormatter'
import { VueAutosuggest } from 'vue-autosuggest'
import ControllingCustomerTableWrapper from '@/components/controlling/tables/ControllingCustomerTableWrapper'
import ControllingColorKey from '@/components/controlling/ControllingColorKey'

export default {
  name: 'ControllingMonthTab',
  mixins: [ApiMixin, NotificationMixin],
  components: { ControllingCustomerTableWrapper, VueAutosuggest, ControllingColorKey },
  props: {
    years: {
      required: true,
      type: Array
    },
    months: {
      required: true,
      type: Array
    },
    reloadDataProp: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      tableData: [],
      aggregationObject: {
        type: 'month',
        name: '',
        childAggregations: null
      },
      filters: {
        year: LocalDate.currentYear(),
        month: LocalDate.currentMonth(),
        customer: '',
        project: ''
      },
      customerQuery: '',
      projectQuery: '',
      // TODO set tables busy
      reviewTableControls: {
        selected: '',
        totalRows: 1,
        currentPage: this.$route.params.pageNumber ? this.$route.params.pageNumber : 1,
        perPage: 50,
        sortBy: 'startDate'
      },
      messages: {
        default: true,
        loading: false,
        emptyFiltered: false,
        error: false
      }
    }
  },
  mounted() {
    this.loadMonthlyData()
  },
  watch: {
    reloadDataProp: function () {
      this.loadMonthlyData()
    },
    filters: {
      handler() {
        this.loadMonthlyData()
      },
      deep: true
    }
  },
  computed: {
    ProjectSuggestions() {
      if (this.tableData.length === 0) {
        return []
      } else {
        const suggestions = []
        this.tableData.forEach(customer => {
          customer.childAggregations.forEach(project => {
            suggestions.push(project.name)
          })
        })
        const filteredSuggestions = suggestions.filter(item => {
          return item.toLowerCase().startsWith(this.projectQuery.toLowerCase())
        })
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    },
    CustomerSuggestions() {
      if (this.tableData.length === 0) {
        return []
      } else {
        const suggestions = []
        this.tableData.forEach(customer => {
          suggestions.push(customer.name)
        })
        const filteredSuggestions = suggestions.filter(item => {
          return item.toLowerCase().startsWith(this.customerQuery.toLowerCase())
        })
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    }
  },
  methods: {
    emitTicketInfo(ticket) {
      this.$emit('row-click', {
        ticket: ticket,
        user: '',
        year: this.filters.year,
        month: this.filters.month,
        week: ''
      })
    },
    emitCustomerProjectInfo(element) {
      element.year = this.filters.year
      element.month = this.filters.month
      this.$emit('project-header-row-click', element)
    },
    emitCustomerInfo(element) {
      element.year = element.year ? element.year : this.filters.year
      this.$emit('customer-header-row-click', element)
    },
    emitTimeInfo(timePeriod) {
      timePeriod.year = timePeriod.year ? timePeriod.year : this.filters.year
      timePeriod.title = this.months[timePeriod.month - 1].text
      this.$emit('time-header-row-click', timePeriod)
    },
    loadMonthlyData() {
      this.messages.default = false
      this.messages.emptyFiltered = false
      this.messages.loading = true
      let year = this.filters.year
      let month = this.filters.month
      let customerName = this.filters.customer
      let projectName = this.filters.project
      let self = this
      self.tableData = []
      return this.getRequest(
        `/table/controlling/month?year=${year}&month=${month}&customerName=${customerName}&projectName=${projectName}`,
        new RequestConfig()
          .onSuccess(res => {
            self.tableData = res.data
            self.aggregationObject.name = self.filters.month
            self.aggregationObject.childAggregations = res.data
            self.messages.loading = false
            self.messages.error = false
            if (self.tableData.length === 0) {
              self.messages.emptyFiltered = true
            } else {
              self.messages.emptyFiltered = false
            }
          })
          .onError(() => {
            self.tableData = []
            self.messages.loading = false
            self.messages.emptyFiltered = false
            self.messages.error = true
          })
      )
    },
    selectCustomerHandler(item) {
      if (!item) {
        this.customerQuery = ''
      } else {
        this.customerQuery = item.item
      }
      this.filters.customer = this.customerQuery
    },
    selectProjectHandler(item) {
      if (!item) {
        this.projectQuery = ''
      } else {
        this.projectQuery = item.item
      }
      this.filters.project = this.projectQuery
    },
    resetCustomerFilter() {
      this.filters.customer = ''
    },
    resetProjectFilter() {
      this.filters.project = ''
    },
    unselectIfEmpty(type) {
      switch (type) {
        case 'customer':
          if (this.customerQuery === '') {
            this.resetCustomerFilter()
          }
          break
        case 'project':
          if (this.projectQuery === '') {
            this.resetProjectFilter()
          }
          break
      }
    }
  }
}
</script>

<style scoped>
.controlling-row-single-project + .controlling-row-single-project {
  margin-top: 1.5rem;
}
</style>
