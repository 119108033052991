<template>
  <div>
    <div class="mt-3 mb-2">
      <h4 class="text-center">{{ header }}</h4>
      <div class="text-center m-0" v-if="isTicketOverview && state.noProject">
        <p>{{ $t('controlling.table.no-project') }}</p>
      </div>
      <div v-else>
        <p class="text-center m-0" v-if="state.default">{{ $t('controlling.table.default') }}</p>
        <p class="text-center m-0" v-if="state.emptyFiltered">
          {{ $t('controlling.table.empty-filtered-text') }}
        </p>
        <p class="text-center m-0" v-if="state.loading">{{ $t('controlling.table.loading') }}</p>
        <p class="text-center m-0" v-if="state.error">{{ $t('controlling.table.error') }}</p>
      </div>
    </div>

    <div v-if="state.loaded && !state.noProject && !state.emptyFiltered">
      <b-row align-h="between" align-v="end" class="mt-2 controlling-row-header">
        <b-col cols="8">
          <h4 class="mb-2 ml-1 mt-2">
            {{ aggregationObject.type === 'customer' ? aggregationObject.name : 'Wrong type' }}
          </h4>
        </b-col>
        <b-col />
        <b-col cols="1">
          <b-form-text
            >{{ $t('validation.totalDuration') }}:
            <strong>{{ durationInMinutesToTime(totalDuration(tableData)) }}</strong></b-form-text
          >
        </b-col>
        <b-col cols="1">
          <b-form-text class="text-right"
            >{{ $t('controlling.billable') }}:
            <strong>{{ durationInMinutesToTime(totalBillableDuration(tableData)) }}</strong></b-form-text
          >
        </b-col>
      </b-row>

      <div class="controlling-row-content pt-0 pl-1" v-if="state.loaded && !state.emptyFiltered">
        <b-table
          class="mb-0"
          small
          fixed
          hover="hover"
          thead-class="hidden_header"
          :fields="headFields"
          :items="[]"
          @row-clicked="emitInfo"
        >
          <template #bottom-row>
            <td style="width: 15%" class="font-weight-bold text-muted pl-0">
              {{ costCenter }}
            </td>
            <td style="width: 60%" class="font-weight-bold text-muted pl-0">{{ $t('datetime.year.year') }}</td>
            <td class="font-weight-bold text-right text-muted">
              {{ $t('controlling.duration') }}
            </td>
            <td class="font-weight-bold text-right text-muted">
              {{ $t('controlling.billable') }}
            </td>
          </template>
        </b-table>
      </div>
      <div class="controlling-row-content pt-0 pl-1" v-if="state.loaded && !state.emptyFiltered">
        <b-table class="mb-0" small fixed hover="hover" thead-class="hidden_header" :fields="headFields" :items="[]">
          <template #bottom-row>
            <td class="px-0">
              <div
                v-for="rootElement in aggregationObject.childAggregations"
                :key="rootElement.name"
                class="controlling-row-single-project"
              >
                <ControllingThreeLevelInnerTable
                  :hide-first-level-header-button="true"
                  :hide-second-level-header-button="isTicketOverview"
                  :rootElement="rootElement"
                  @row-click="emitInfo(rootElement, ...arguments)"
                  @project-header-row-click="emitInfo(rootElement, ...arguments)"
                  @customer-header-row-click="emitInfo(rootElement, ...arguments)"
                  @year-header-row-click="emitInfo(rootElement, ...arguments)"
                />
              </div>
            </td>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import ControllingThreeLevelInnerTable from '@/components/controlling/tables/ControllingThreeLevelInnerTable'

export default {
  name: 'ControllingCustomerGenericTable',
  components: { ControllingThreeLevelInnerTable },
  props: {
    tableData: {
      required: true,
      type: Array
    },
    aggregationObject: {
      required: true,
      type: Object
    },
    headFields: {
      required: true,
      type: Array
    },
    costCenter: {
      required: true,
      type: String
    },
    header: {
      required: true,
      type: String
    },
    state: {
      required: true,
      type: Object
    },
    isTicketOverview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    emitInfo(rootElement, object) {
      if (object.type === 'customerTicket') {
        let ticketName = rootElement.name
        let ticketId = this.parseRowId(object.id)
        this.emitTicketInfo(ticketName, ticketId, object.name, object.year)
      } else if (object.type === 'customerProjectUser') {
        let project = this.parseRowId(object.id)
        let customer = this.aggregationObject.name
        this.emitProjectInfo(customer, project, object.name, object.year)
      } else if (object.type === 'projectYear') {
        this.$emit('row-click', {
          type: object.type,
          year: object.year,
          project: object.project,
          modalTitle: object.project + ' - ' + object.year
        })
      } else if (object.type === 'ticketYear') {
        this.$emit('row-click', {
          type: object.type,
          year: object.year,
          ticket: object.ticket,
          modalTitle: object.ticket + ' - ' + object.year
        })
      }
    },
    emitTicketInfo(ticketName, ticketId, userName, year) {
      this.$emit('row-click', {
        ticketId: ticketId,
        userName: userName,
        year: year,
        modalTitle: ticketName + ' - ' + userName
      })
    },
    emitProjectInfo(customer, project, userName, year) {
      this.$emit('row-click', {
        customer: customer,
        project: project,
        userName: userName,
        year: year,
        modalTitle: project + ' - ' + userName
      })
    },
    parseRowId(rowId) {
      return rowId.split('#')[0]
    },
    durationInMinutesToTime(minutes) {
      return LocalDateTimeFormatter.durationInMinutesToTime(minutes)
    },
    totalDuration() {
      let totalDuration = 0
      this.aggregationObject.childAggregations.forEach(customer => {
        let customerDuration = 0
        customer.childAggregations.forEach(project => {
          let projectDuration = 0
          project.details.forEach(ticket => {
            projectDuration = projectDuration + ticket.duration
          })
          customerDuration = customerDuration + projectDuration
        })
        totalDuration = totalDuration + customerDuration
      })
      return totalDuration
    },
    totalBillableDuration() {
      let totalDuration = 0
      this.aggregationObject.childAggregations.forEach(customer => {
        let customerDuration = 0
        customer.childAggregations.forEach(project => {
          let projectDuration = 0
          project.details.forEach(ticket => {
            projectDuration = projectDuration + ticket.billableDuration
          })
          customerDuration = customerDuration + projectDuration
        })
        totalDuration = totalDuration + customerDuration
      })
      return totalDuration
    }
  }
}
</script>

<style scoped>
.controlling-row-single-project + .controlling-row-single-project {
  margin-top: 1.5rem;
}
</style>
