<template>
  <b-container id="controlling-tab-content-container" fluid class="p-1">
    <b-row id="controlling-filter" align-h="start" align-v="end" class="mx-0 mb-3">
      <b-col cols="2" xl="1" class="px-0">
        <b-form-select v-model="filters.year" :options="years" />
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="userQuery"
          :section-configs="{ default: { limit: 10 } }"
          :suggestions="userSuggestions"
          :input-props="{ id: 'userSearch', placeholder: this.$t('validation.search-user') }"
          @selected="selectUserHandler"
          @input="resetUserFilter"
          @change="unselectIfEmpty('user')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <b-form-select v-model="filters.month" :options="months">
          <template #first>
            <b-form-select-option :value="null">{{ $t('datetime.select-month') }}</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="customerQuery"
          :section-configs="{ default: { limit: 6 } }"
          :suggestions="customerSuggestions"
          :input-props="{ id: 'customerSearch', placeholder: this.$t('validation.search-customer') }"
          @selected="selectCustomerHandler"
          @input="resetCustomerFilter"
          @change="unselectIfEmpty('customer')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="2" xl="1" class="ml-2 px-0">
        <vue-autosuggest
          v-model="projectQuery"
          :section-configs="{ default: { limit: 6 } }"
          :suggestions="projectSuggestions"
          :input-props="{ id: 'projectSearch', placeholder: this.$t('validation.search-project') }"
          @selected="selectProjectHandler"
          @input="resetProjectFilter"
          @change="unselectIfEmpty('project')"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{ suggestion.item }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
    </b-row>

    <b-card>
      <p class="text-center m-0" v-if="messages.default">{{ $t('controlling.table.default') }}</p>
      <p class="text-center m-0" v-if="messages.emptyFiltered">
        {{ $t('controlling.table.empty-filtered-text') }}
      </p>
      <p class="text-center m-0" v-if="messages.loading">{{ $t('controlling.table.loading') }}</p>
      <p class="text-center m-0" v-if="messages.error">{{ $t('controlling.table.error') }}</p>

      <div
        v-for="user in tableData"
        :key="user.name"
        :class="{ 'mb-5': tableData.length > 1, 'controlling-row': true, 'employee-controlling-row': true }"
      >
        <b-row align-h="between" align-v="end" class="mt-2 controlling-row-header">
          <b-col cols="4">
            <h4 class="mb-2 ml-1 mt-2">{{ user.name }}</h4>
          </b-col>
          <b-col />
          <b-col cols="1">
            <b-form-text
              >{{ $t('validation.totalDuration') }}:
              <strong>{{ durationInMinutesToTime(totalDuration(user.childAggregations)) }}</strong></b-form-text
            >
          </b-col>
          <b-col cols="1">
            <b-form-text class="text-right"
              >{{ $t('controlling.billable') }}:
              <strong>{{ durationInMinutesToTime(totalBillableDuration(user.childAggregations)) }}</strong></b-form-text
            >
          </b-col>
        </b-row>

        <div class="controlling-row-content pt-0">
          <b-table class="mb-0" small fixed hover="hover" thead-class="hidden_header" :fields="headFields" :items="[]">
            <template #bottom-row>
              <td style="width: 15%" class="font-weight-bold text-muted">{{ $t('datetime.month.month') }}</td>
              <td style="width: calc(85% / 100 * 15)" class="font-weight-bold text-muted">
                {{ $t('general.customer') }}
              </td>
              <td class="w-50 font-weight-bold text-muted">{{ $t('controlling.cost-center') }}</td>
              <td class="font-weight-bold text-right text-muted pr-0">
                {{ $t('controlling.duration') }}
              </td>
              <td class="font-weight-bold text-right text-muted">
                {{ $t('controlling.billable') }}
              </td>
            </template>
          </b-table>

          <div v-for="month in user.childAggregations" :key="month.name" class="controlling-row-single-month ml-1 mr-0">
            <ControllingMonthTable
              :month="month"
              @row-click="emitTicketInfo(user, month, ...arguments)"
              @user-header-row-click="emitUserInfo(user, ...arguments)"
              @customer-header-row-click="emitCustomerInfo(user, ...arguments)"
              @project-header-row-click="emitCustomerProjectInfo(user, ...arguments)"
            />
          </div>
        </div>
      </div>
    </b-card>
    <ControllingColorKey class="mt-3" />
  </b-container>
</template>

<script>
import { LocalDate, LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import { VueAutosuggest } from 'vue-autosuggest'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import ControllingMonthTable from '@/components/controlling/tables/ControllingMonthTable'
import ControllingColorKey from '@/components/controlling/ControllingColorKey'

export default {
  name: 'ControllingEmployeeTab',
  mixins: [ApiMixin, NotificationMixin],
  components: { ControllingMonthTable, VueAutosuggest, ControllingColorKey },
  props: {
    years: {
      required: true,
      type: Array
    },
    months: {
      required: true,
      type: Array
    },
    reloadDataProp: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      tableData: [],
      filters: {
        year: LocalDate.currentYear(),
        month: LocalDate.currentMonth(),
        customer: '',
        project: '',
        user: ''
      },
      customerQuery: '',
      projectQuery: '',
      userQuery: '',
      messages: {
        default: true,
        loading: false,
        emptyFiltered: false,
        error: false
      }
    }
  },
  mounted() {
    this.loadUserData()
  },
  watch: {
    reloadDataProp: function () {
      this.loadUserData()
    },
    filters: {
      handler() {
        this.loadUserData()
      },
      deep: true
    }
  },
  computed: {
    projectSuggestions() {
      if (this.tableData.length === 0) {
        return []
      } else {
        const suggestions = []
        this.tableData.forEach(user => {
          user.childAggregations.forEach(month => {
            month.childAggregations.forEach(customer => {
              customer.childAggregations.forEach(project => {
                suggestions.push(project.name)
              })
            })
          })
        })
        const filteredSuggestions = suggestions.filter(item => {
          return item.toLowerCase().startsWith(this.projectQuery.toLowerCase())
        })
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    },
    customerSuggestions() {
      if (this.tableData.length === 0) {
        return []
      } else {
        const suggestions = []
        this.tableData.forEach(user => {
          user.childAggregations.forEach(month => {
            month.childAggregations.forEach(customer => {
              suggestions.push(customer.name)
            })
          })
        })
        const filteredSuggestions = suggestions.filter(item => {
          return item.toLowerCase().startsWith(this.customerQuery.toLowerCase())
        })
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    },
    userSuggestions() {
      if (this.tableData.length === 0) {
        return []
      } else {
        const suggestions = []
        this.tableData.forEach(user => {
          suggestions.push(user.name)
        })
        const filteredSuggestions = suggestions.filter(item => {
          return item.toLowerCase().startsWith(this.userQuery.toLowerCase())
        })
        return [{ data: [...new Set(filteredSuggestions)] }]
      }
    },
    headFields() {
      return [
        { key: 'user', label: 'userName', sortable: false, width: 'auto' },
        { key: 'customer', label: this.$t('general.customer'), sortable: false, width: 'auto' },
        { key: 'projectName', label: this.$t('general.project'), sortable: false, width: 'auto' },
        { key: 'duration', label: this.$t('validation.duration'), sortable: false, width: 'auto' },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false, width: 'auto' }
      ]
    },
    fields() {
      return [
        { key: 'projectName', label: this.$t('general.project'), sortable: false, width: 'auto' },
        { key: 'duration', label: this.$t('validation.duration'), sortable: false, width: 'auto' },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false, width: 'auto' }
      ]
    }
  },
  methods: {
    emitTicketInfo(user, month, object) {
      this.$emit('row-click', {
        ticket: object.ticket,
        user: user.name,
        year: this.filters.year,
        month: month.name,
        week: ''
      })
    },
    emitUserInfo(user, object) {
      object.year = this.filters.year
      object.user = user.name
      object.title = user.name + ' - ' + this.months[this.filters.month - 1].text
      this.$emit('user-header-row-click', object)
    },
    emitCustomerProjectInfo(user, element) {
      element.year = this.filters.year
      element.month = this.filters.month
      element.userName = user.name
      this.$emit('project-header-row-click', element)
    },
    emitCustomerInfo(user, element) {
      element.year = element.year ? element.year : this.filters.year
      element.month = this.filters.month
      element.userName = user.name
      this.$emit('customer-header-row-click', element)
    },
    selectCustomerHandler(item) {
      if (!item) {
        this.customerQuery = ''
      } else {
        this.customerQuery = item.item
      }
      this.filters.customer = this.customerQuery
    },
    selectProjectHandler(item) {
      if (!item) {
        this.projectQuery = ''
      } else {
        this.projectQuery = item.item
      }
      this.filters.project = this.projectQuery
    },
    selectUserHandler(item) {
      if (!item) {
        this.userQuery = ''
      } else {
        this.userQuery = item.item
      }
      this.filters.user = this.userQuery
    },
    resetUserFilter() {
      this.filters.user = ''
    },
    resetCustomerFilter() {
      this.filters.customer = ''
    },
    resetProjectFilter() {
      this.filters.project = ''
    },
    unselectIfEmpty(type) {
      switch (type) {
        case 'customer':
          if (this.customerQuery === '') {
            this.resetCustomerFilter()
          }
          break
        case 'project':
          if (this.projectQuery === '') {
            this.resetProjectFilter()
          }
          break
        case 'user':
          if (this.userQuery === '') {
            this.resetUserFilter()
          }
          break
      }
    },
    loadUserData() {
      this.messages.default = false
      this.messages.emptyFiltered = false
      this.messages.loading = true
      let year = this.filters.year
      let month = ''
      if (this.filters.month) {
        month = this.filters.month
      }
      let customerName = this.filters.customer
      let projectName = this.filters.project
      let userName = this.filters.user
      let self = this
      self.tableData = []
      return this.getRequest(
        `/table/controlling/user?year=${year}&month=${month}&customerName=${customerName}&projectName=${projectName}&userName=${userName}`,
        new RequestConfig()
          .onSuccess(res => {
            self.tableData = res.data
            self.messages.loading = false
            self.messages.error = false
            if (self.tableData.length === 0) {
              self.messages.emptyFiltered = true
            } else {
              self.messages.emptyFiltered = false
            }
          })
          .onError(() => {
            self.tableData = []
            self.messages.loading = false
            self.messages.emptyFiltered = false
            self.messages.error = true
          })
      )
    },
    durationInMinutesToTime(minutes) {
      return LocalDateTimeFormatter.durationInMinutesToTime(minutes)
    },
    totalDuration(user) {
      let totalDuration = 0
      user.forEach(user => {
        let userDuration = 0
        user.childAggregations.forEach(customer => {
          let customerDuration = 0
          customer.childAggregations.forEach(project => {
            let projectDuration = 0
            project.details.forEach(ticket => {
              projectDuration = projectDuration + ticket.duration
            })
            customerDuration = customerDuration + projectDuration
          })
          userDuration = userDuration + customerDuration
        })
        totalDuration = totalDuration + userDuration
      })
      return totalDuration
    },
    totalBillableDuration(user) {
      let totalDuration = 0
      user.forEach(user => {
        let userDuration = 0
        user.childAggregations.forEach(customer => {
          let customerDuration = 0
          customer.childAggregations.forEach(project => {
            let projectDuration = 0
            project.details.forEach(ticket => {
              projectDuration = projectDuration + ticket.billableDuration
            })
            customerDuration = customerDuration + projectDuration
          })
          userDuration = userDuration + customerDuration
        })
        totalDuration = totalDuration + userDuration
      })
      return totalDuration
    }
  }
}
</script>

<style scoped>
.controlling-row-single-month + .controlling-row-single-month {
  margin-top: 1.5rem;
}
</style>
