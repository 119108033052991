<template>
  <b-modal
    v-model="visibleSync"
    size="xl"
    centered
    :title="$t('validation.add-subtractions')"
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-bg-variant="footerBgVariant"
    :footer-text-variant="footerTextVariant"
  >
    <b-container fluid>
      <b-table
        show-empty
        small
        fixed
        stacked="md"
        hover="hover"
        :tbody-tr-class="rowClass"
        :items="priceAccumulations"
        :fields="subtractionFields"
        :sort-by="subtractionTableControls.sortBy"
        :sort-desc="false"
        striped
        @row-selected="onRowSelect"
        ref="subtractionTable"
        head-variant="light"
      >
        <template v-slot:cell(price)="row">
          {{ formatPrice(row.item.price) }}
        </template>
        <template v-slot:cell(subtraction)="row">
          <b-form-input v-model="row.item.subtraction" size="sm" type="number" />
        </template>
        <template v-slot:cell(discountedPrice)="row">
          {{ formatPrice(row.item.price - row.item.subtraction) }}
        </template>
      </b-table>
      <b-row>
        <b-col>{{ $t('validation.total-net-price') }}</b-col>
        <b-col class="text-right">{{ formatPrice(accumulatedTotalPrice) }}</b-col>
        <b-col>
          <b-form-input v-model="totalNetPriceSubtraction" size="sm" type="number" />
        </b-col>
        <b-col class="text-right">{{ formatPrice(accumulatedTotalPrice - totalNetPriceSubtraction) }}</b-col>
      </b-row>
      <b-row>
        <b-col>{{ $t('validation.vat') }}</b-col>
        <b-col class="text-right">{{ vat * 100 }}% </b-col>
        <b-col />
        <b-col class="text-right">{{ formatPrice((accumulatedTotalPrice - totalNetPriceSubtraction) * vat) }}</b-col>
      </b-row>
      <b-row>
        <b-col>{{ $t('validation.total-gross-price') }}</b-col>
        <b-col />
        <b-col />
        <b-col class="text-right">{{
          formatPrice((accumulatedTotalPrice - totalNetPriceSubtraction) * (1 + vat))
        }}</b-col>
      </b-row>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button class="float-right ml-1" variant="primary" @click="mailInvoice">
          {{ $t('validation.mail-pdf') }}
        </b-button>
        <b-button class="float-right" variant="primary" @click="generateInvoice">
          {{ $t('validation.generate-pdf') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'

export default {
  name: 'ControllingSubtractionModal',
  mixins: [ApiMixin, NotificationMixin],
  props: {
    priceAccumulations: {
      required: true,
      type: Array
    },
    visible: {
      required: true,
      type: Boolean,
      default: false
    },
    ttus: {
      required: true,
      type: Array
    },
    startDate: {
      required: true,
      type: String
    },
    endDate: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      subtractionTableControls: {
        sortBy: 'name'
      },
      totalNetPriceSubtraction: 0,
      vat: this.$store.getters.getOrganisation.defaultInvoiceVatRateInPercents / 100,
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark'
    }
  },
  computed: {
    subtractionFields() {
      return [
        { key: 'name', label: this.$t('validation.project'), sortable: false },
        { key: 'price', label: this.$t('validation.base-price'), sortable: false, class: 'text-right' },
        { key: 'subtraction', label: this.$t('validation.subtraction'), sortable: false },
        { key: 'discountedPrice', label: this.$t('validation.discounted-price'), sortable: false, class: 'text-right' }
      ]
    },
    accumulatedTotalPrice() {
      let totalCost = 0
      for (let project of this.priceAccumulations) {
        totalCost += project.price - project.subtraction
      }
      return totalCost
    },
    visibleSync: {
      get: function () {
        return this.visible
      },
      set: function (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.reviewStatus === 'HIGHLIGHTED') return 'table-danger'
      if (item.reviewStatus === 'REVIEWED') return 'table-info'
      if (item.reviewStatus === 'BILLED') return 'table-success'
    },
    onRowSelect(items) {
      this.selectedRows = items
    },
    formatPrice(priceToFormat) {
      let numDigits = priceToFormat.toString().length
      let numIntegralDigits = Math.floor(priceToFormat).toString().length
      let difference = numDigits - numIntegralDigits
      let suffix = ',00 €'
      if (difference < 4) {
        return priceToFormat.toString().replace('.', ',') + suffix.substring(difference)
      }
      return (
        priceToFormat
          .toString()
          .replace('.', ',')
          .substring(0, numIntegralDigits + 3) + ' €'
      )
    },
    generateInvoice() {
      let invoiceData = this.createInvoiceData()
      let url = '/invoice/pdf'
      let self = this
      this.postRequest(
        url,
        invoiceData,
        new RequestConfig().withAxiosConfig({ responseType: 'arraybuffer' }).onSuccess(res => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          // parse filename out of content-dispo header, default file.pdf
          link.download = 'file.pdf'
          const disposition = res.headers['content-disposition']
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
              link.download = matches[1].replace(/['"]/g, '')
            }
          }
          link.click()
          URL.revokeObjectURL(link.href)
          link.remove()
          // set current ttus to billed & set current status filter to billed
          self.invoiceAfterAction()
          self.visibleSync = false
        })
      )
    },
    createInvoiceData() {
      let ttuIds = []
      for (let ttu of this.ttus) {
        ttuIds.push(ttu.matchingTimeTrackingUnitId)
      }
      let projectSubtractions = []
      for (let project of this.priceAccumulations) {
        let projectSubtraction = {
          projectId: project.id,
          subtraction: project.subtraction
        }
        projectSubtractions.push(projectSubtraction)
      }
      return {
        ttuIds: ttuIds,
        startDate: this.startDate,
        endDate: this.endDate,
        totalSubtraction: this.totalNetPriceSubtraction,
        projectSubtractions: projectSubtractions
      }
    },
    invoiceAfterAction() {
      // set current ttus to billed
      for (let ttu of this.ttus) {
        ttu.reviewTimeTrackingUnit.reviewStatus = 'BILLED'
      }
      // set current status filter to billed
      this.reviewStatusQuery = 'Billed'
      this.totalNetPriceSubtraction = 0
      this.visibleSync = false
    },
    mailInvoice() {
      let invoiceData = this.createInvoiceData()
      let url = '/invoice/mail'
      let self = this
      this.postRequest(
        url,
        invoiceData,
        new RequestConfig().onSuccess(() => {
          // set current ttus to billed & set current status filter to billed
          self.invoiceAfterAction()
        })
      )
    }
  }
}
</script>
