<template>
  <b-table
    class="mb-0 controlling-single-element-table"
    show-empty
    small
    fixed
    hover="hover"
    thead-class="hidden_header"
    :tbody-tr-class="rowClass"
    :fields="fields"
    :items="element.details"
    @row-clicked="emitTicketInfo"
  >
    <template #top-row="row">
      <td class="controllingTableFirstCol font-weight-bold pl-0">
        <div class="align-items-center">
          <span class="pt-1">{{
            element.type === 'projectCostGroup'
              ? element.name === 'no_group'
                ? $t('controlling.project-cost-group-no-group')
                : element.name
              : element.name
          }}</span>
          <ControllingReviewHeaderButton
            v-if="showReviewButton"
            class="ml-3"
            :element="row"
            @header-row-click="emitProjectInfo"
          />
        </div>
      </td>
      <td class="font-weight-bold text-right">
        {{ getAggregatedMinutesInTimeFormat(element, 'internal') }}
      </td>
      <td class="font-weight-bold text-right">
        {{ getAggregatedMinutesInTimeFormat(element, 'billable') }}
      </td>
    </template>
    <template v-slot:cell(costCenter)="row">
      <span
        v-if="row.item.ttuStatus === 'RUNNING'"
        v-b-tooltip.hover
        :title="$t('controlling.warningContainsRunningTtus')"
      >
        <b-icon icon="exclamation-triangle-fill" variant="danger" />
      </span>
      <span class="ml-3">{{ row.item.name }}</span>
    </template>
    <template v-slot:cell(duration)="row">
      <span class="float-right">{{ durationInMinutesToTime(row.item.duration) }}</span>
    </template>
    <template v-slot:cell(billableDuration)="row">
      <span class="float-right">{{ durationInMinutesToTime(row.item.billableDuration) }}</span>
    </template>
  </b-table>
</template>

<script>
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import ControllingReviewHeaderButton from '@/components/controlling/ControllingReviewHeaderButton'

export default {
  name: 'ControllingInnerTable',
  components: { ControllingReviewHeaderButton },
  props: {
    element: {
      type: Object,
      required: true
    },
    showReviewButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    fields() {
      return [
        { key: 'costCenter', label: this.$t('controlling.cost-center'), sortable: false },
        { key: 'duration', label: this.$t('controlling.duration'), sortable: false },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false }
      ]
    }
  },
  methods: {
    getAggregatedMinutesInTimeFormat(element, type) {
      let minutes = 0
      switch (type) {
        case 'internal':
          element.details.forEach(ticket => {
            minutes = minutes + ticket.duration
          })
          break
        case 'billable':
          element.details.forEach(ticket => {
            minutes = minutes + ticket.billableDuration
          })
          break
      }
      return LocalDateTimeFormatter.durationInMinutesToTime(minutes)
    },
    durationInMinutesToTime(minutes) {
      return LocalDateTimeFormatter.durationInMinutesToTime(minutes)
    },
    emitTicketInfo(row) {
      let emitObject = {
        id: row.id,
        name: row.name,
        type: row.type
      }
      if (this.element.type === 'year') {
        emitObject.year = this.element.name
      }
      if (this.element.type === 'project') {
        emitObject.projectName = this.element.name
      }
      if (this.element.type === 'projectCostGroup') {
        emitObject.projectCostGroup = this.element.name
      }
      this.$emit('row-click', emitObject)
    },
    emitProjectInfo() {
      this.$emit('header-row-click', { name: this.element.name, type: this.element.type })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.reviewStatus === 'HIGHLIGHTED') return 'table-danger controllingTableRow controlling-ticket-row'
      if (item.reviewStatus === 'REVIEWED') return 'table-info controllingTableRow controlling-ticket-row'
      if (item.reviewStatus === 'BILLED') return 'table-success controllingTableRow controlling-ticket-row'
      if (item.reviewStatus === 'MIXED') return 'table-warning controllingTableRow controlling-ticket-row'
      if (item.reviewStatus === null) return 'controllingTableRow controlling-ticket-row'
    }
  }
}
</script>

<style lang="scss" scoped>
.b-table-top-row {
  pointer-events: none;

  td {
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }
}
</style>
