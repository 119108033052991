<template>
  <b-table
    class="mb-0 aggregated-duration-table"
    small
    fixed
    hover="hover"
    thead-class="hidden_header"
    :fields="controllingWeekHeadFields"
    :items="[]"
  >
    <template #top-row>
      <td class="controllingTableFirstCol border-0 pl-0" />
      <td class="border-0 font-weight-bold text-right pr-0">
        {{ getAggregatedMinutesForWeekInTimeFormat('internal') }}
      </td>
      <td class="border-0 font-weight-bold text-right pr-0">
        {{ getAggregatedMinutesForWeekInTimeFormat('billable') }}
      </td>
    </template>
  </b-table>
</template>

<script>
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'

export default {
  name: 'AggregatedDurationTable',
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      calculatedDuration: 0,
      calculatedBillableDuration: 0
    }
  },
  computed: {
    controllingWeekHeadFields() {
      return [
        { key: 'emptyCell', label: '', sortable: false },
        { key: 'duration', label: this.$t('controlling.duration'), sortable: false },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false }
      ]
    }
  },
  methods: {
    getAggregatedMinutesForWeekInTimeFormat(type) {
      let totalMinutes = 0
      switch (type) {
        case 'internal':
          this.projects.forEach(project => {
            let projectMinutes = 0
            project.details.forEach(ticket => {
              projectMinutes = projectMinutes + ticket.duration
            })
            totalMinutes = totalMinutes + projectMinutes
          })
          break
        case 'billable':
          this.projects.forEach(project => {
            let projectMinutes = 0
            project.details.forEach(ticket => {
              projectMinutes = projectMinutes + ticket.billableDuration
            })
            totalMinutes = totalMinutes + projectMinutes
          })
          break
      }
      return LocalDateTimeFormatter.durationInMinutesToTime(totalMinutes)
    }
  }
}
</script>

<style scoped></style>
