<template>
  <b-table
    class="mb-0 controlling-single-month-table"
    small
    fixed
    hover="hover"
    thead-class="hidden_header"
    :fields="fields"
    :items="[]"
  >
    <template #top-row>
      <td style="width: 15%" class="pl-0 align-bottom font-weight-bold align-items-center">
        {{ parseMonth(month.name) }}
        <ControllingReviewHeaderButton class="ml-3" :element="{}" @header-row-click="emitUserInfo" />
      </td>
      <td style="width: 85%" class="align-bottom">
        <AggregatedDurationTableCustomer :customer="month.childAggregations" />
      </td>
    </template>
    <template #bottom-row>
      <td class="px-0" />
      <td class="px-0">
        <div v-for="customer in month.childAggregations" :key="customer.name" class="controlling-row-single-customer">
          <ControllingThreeLevelInnerTable
            :rootElement="customer"
            @row-click="emitTicketInfo"
            @project-header-row-click="emitCustomerProjectInfo"
            @customer-header-row-click="emitCustomerInfo"
          />
        </div>
      </td>
    </template>
  </b-table>
</template>

<script>
import AggregatedDurationTableCustomer from './AggregatedDurationTableCustomer'
import ControllingThreeLevelInnerTable from './ControllingThreeLevelInnerTable'
import { LocalDate } from '@/util/LocalDateTimeFormatter'
import ControllingReviewHeaderButton from '@/components/controlling/ControllingReviewHeaderButton'
export default {
  name: 'ControllingMonthTable',
  components: { ControllingReviewHeaderButton, ControllingThreeLevelInnerTable, AggregatedDurationTableCustomer },
  props: {
    month: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields() {
      return [
        { key: 'inputObject', label: '', sortable: false, width: '70%' },
        { key: 'projectTable', label: '', sortable: false }
      ]
    }
  },
  methods: {
    emitTicketInfo(ticket) {
      this.$emit('row-click', { ticket: ticket })
    },
    emitCustomerProjectInfo(element) {
      this.$emit('project-header-row-click', element)
    },
    emitCustomerInfo(element) {
      this.$emit('customer-header-row-click', element)
    },
    emitUserInfo() {
      let emitObject = { month: this.month.name }
      this.$emit('user-header-row-click', emitObject)
    },
    parseMonth(month) {
      return LocalDate.parseMonth(month)
    }
  }
}
</script>

<style scoped>
.controlling-row-single-customer + .controlling-row-single-customer {
  margin-top: 1.5rem;
}
</style>
