<template>
  <b-table
    class="mb-0 aggregated-duration-table-customer"
    small
    fixed
    hover="hover"
    thead-class="hidden_header"
    :fields="controllingWeekHeadFields"
    :items="[]"
  >
    <template #top-row>
      <td style="width: 15%" class="controllingTableFirstCol border-0 pl-0" />
      <td style="width: calc(85% / 100 * 70)" class="border-0" />
      <td class="border-0 font-weight-bold text-right pr-0">
        {{ getAggregatedMinutesForCustomerInTimeFormat('internal') }}
      </td>
      <td class="border-0 font-weight-bold text-right pr-0">
        {{ getAggregatedMinutesForCustomerInTimeFormat('billable') }}
      </td>
    </template>
  </b-table>
</template>

<script>
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'

export default {
  name: 'AggregatedDurationTableCustomer',
  props: {
    customer: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      calculatedDuration: 0,
      calculatedBillableDuration: 0
    }
  },
  computed: {
    controllingWeekHeadFields() {
      return [
        { key: 'firstEmptyCell', label: '', sortable: false },
        { key: 'secondEmptyCell', label: '', sortable: false },
        { key: 'duration', label: this.$t('controlling.duration'), sortable: false },
        { key: 'billableDuration', label: this.$t('controlling.billable'), sortable: false }
      ]
    }
  },
  methods: {
    getAggregatedMinutesForCustomerInTimeFormat(type) {
      let totalMinutes = 0
      switch (type) {
        case 'internal':
          this.customer.forEach(customer => {
            let customerMinutes = 0
            customer.childAggregations.forEach(project => {
              let projectMinutes = 0
              project.details.forEach(ticket => {
                projectMinutes = projectMinutes + ticket.duration
              })
              customerMinutes = customerMinutes + projectMinutes
            })
            totalMinutes = totalMinutes + customerMinutes
          })
          break
        case 'billable':
          this.customer.forEach(customer => {
            let customerMinutes = 0
            customer.childAggregations.forEach(project => {
              let projectMinutes = 0
              project.details.forEach(ticket => {
                projectMinutes = projectMinutes + ticket.billableDuration
              })
              customerMinutes = customerMinutes + projectMinutes
            })
            totalMinutes = totalMinutes + customerMinutes
          })
          break
      }
      return LocalDateTimeFormatter.durationInMinutesToTime(totalMinutes)
    }
  }
}
</script>

<style scoped></style>
