<template>
  <b-modal
    v-model="visibleSync"
    size="lg"
    :title="$t('validation.edit-ttu')"
    :cancel-title="$t('general.cancel')"
    cancel-variant="outline-secondary"
    :ok-title="$t('general.save')"
    :ok-disabled="editModalDisabled"
    @ok="editTimeTrackingUnit"
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-bg-variant="footerBgVariant"
    :footer-text-variant="footerTextVariant"
  >
    <div>
      <b-container fluid>
        <!-- Ticket editierbar und Customer + Project mit Breadcrumb -->
        <b-row class="mb-3 w-100">
          <b-input-group :prepend="$t('general.ticket')">
            <b-input
              v-if="timeTrackingUnit.ticket.ticketId"
              v-model="timeTrackingUnit.ticket.ticketId"
              type="text"
              disabled
              class="col-2"
            />
            <b-input v-model="timeTrackingUnit.ticket.name" type="text" disabled></b-input>
          </b-input-group>
        </b-row>
        <b-breadcrumb id="editTicketBreadcrumb" :items="editTimetrackingUnitBreadcrumb"></b-breadcrumb>
        <b-row class="mb-4">
          <b-card class="w-100">
            <b-row class="m-2">
              <b-col class="px-0" cols="8">
                <b-input-group :prepend="$t('timetracking.start')">
                  <Datepicker
                    id="edit-date-input"
                    v-model="timeTrackingUnit.date"
                    :placeholder="this.$t('timetracking.date')"
                    :locale="this.$store.getters.getLocale"
                    :reset-button="false"
                  />
                  <b-input id="edit-start-input" v-model="timeTrackingUnit.startTime" type="time" class="col-4" />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="m-2">
              <b-col class="pr-0" cols="4">
                <b-input-group :prepend="$t('timetracking.break-duration')">
                  <b-input id="break-Length" v-model="timeTrackingUnit.breakDurationInMinutes" type="number" />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="m-2">
              <b-col class="px-0" cols="8">
                <b-input-group :prepend="$t('timetracking.end')">
                  <Datepicker
                    id="end-date-input"
                    v-model="timeTrackingUnit.endDate"
                    :placeholder="this.$t('timetracking.date')"
                    :locale="this.$store.getters.getLocale"
                    :reset-button="false"
                  />
                  <b-input id="edit-end-input" v-model="timeTrackingUnit.endTime" type="time" class="col-4" />
                </b-input-group>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
        <b-row class="mb-3">
          <b-input-group :prepend="$t('timetracking.comment')">
            <b-input id="comment" v-model="timeTrackingUnit.comment" type="text" />
          </b-input-group>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import Datepicker from '@/components/GoldflamDatepicker'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'

export default {
  name: 'ControllingEditModal',
  mixins: [ApiMixin],
  components: { Datepicker },
  props: {
    timeTrackingUnitToEdit: {
      required: true,
      type: Object
    },
    visible: {
      required: true,
      type: Boolean,
      default: false
    },
    updateTtuProp: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      timeTrackingUnit: {
        date: '',
        startDate: null,
        startTime: '',
        endDate: null,
        endTime: '',
        breakDurationInMinutes: null,
        comment: '',
        status: null,
        matchingTimeTrackingUnitId: '',
        ticket: {
          id: '',
          name: '',
          ticketId: '',
          project: {
            id: '',
            fullName: '',
            customer: {
              fullName: ''
            }
          }
        }
      },
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark'
    }
  },
  computed: {
    editModalDisabled() {
      let isModalFilled =
        this.timeTrackingUnit.ticket &&
        this.timeTrackingUnit.date &&
        this.timeTrackingUnit.startTime &&
        this.timeTrackingUnit.endTime &&
        this.timeTrackingUnit.status
      if (this.timeTrackingUnit.status === 'RUNNING') {
        if (!this.timeTrackingUnit.ticket.project) {
          return true
        }
        isModalFilled = this.timeTrackingUnit.ticket && this.timeTrackingUnit.date && this.timeTrackingUnit.startTime
      }
      return !isModalFilled
    },
    editTimetrackingUnitBreadcrumb() {
      if (this.timeTrackingUnit.ticket.ticketId !== '') {
        return [
          { text: this.timeTrackingUnit.ticket.project.customer.fullName },
          { text: this.timeTrackingUnit.ticket.project.fullName }
        ]
      }
      return []
    },
    visibleSync: {
      get: function () {
        return this.visible
      },
      set: function (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  watch: {
    updateTtuProp: function () {
      this.fillTimeTrackingUnit()
    }
  },
  methods: {
    reloadTable() {
      this.$emit('reload-table')
    },
    fillTimeTrackingUnit() {
      this.timeTrackingUnit = this.timeTrackingUnitToEdit
    },
    editTimeTrackingUnit() {
      this.timeTrackingUnit.startDate = LocalDateTimeFormatter.toDateTime(
        new Date(this.timeTrackingUnit.date),
        this.timeTrackingUnit.startTime
      )
      this.timeTrackingUnit.endDate = LocalDateTimeFormatter.toDateTime(
        new Date(this.timeTrackingUnit.date),
        this.timeTrackingUnit.endTime
      )
      const timeTrackingUnitJSON = JSON.stringify(this.timeTrackingUnit)
      const id = this.timeTrackingUnit.matchingTimeTrackingUnitId
      let self = this
      this.postRequest(
        `/timetracking_units/${id}`,
        timeTrackingUnitJSON,
        new RequestConfig().onSuccess(() => {
          self.visibleSync = false
          //self.displaySuccess(self.$t('timetracking.ttu-edited-success'))
          self.reloadTable()
        })
      )
    }
  }
}
</script>

<style scoped></style>
